import React, { useEffect, useRef, useState } from 'react';
import { capitalize, isEmpty, startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
    doFileUploadEntry,
    getDocumentDetailsByID,
    getFileSignedUrl,
    getFileUploadsByDocumentRequestID,
    popup,
    validateFiles,
    signedURL,
    convertTimestampToDate,
    getFileExtension,
    updateDocsRequest,
    deleteDocsRequest,
    getFileExtensionFromPath,
    getContentTypeByExtension,
    handleScroll,
} from '../../utils';
import {
    BlueArrow,
    Delete,
    Edit,
    FeedbackCross,
    GreenPlus,
    IconHover1,
    IconHover2,
    IconPlus,
    DocImage,
} from '../../assets/svg';
import { ImageWithFallback } from './common/ImageWithFallback';
import { ButtonComponent } from './Buttons/ButtonComponent';
import { DOCUMENTS, DOC_REQUEST, MAX_LENGTH, SYMBOL } from '../../constants';
import { setFileViewModalDetails, setLoaderVisibility } from '../../redux';
import { useDebounce } from '../../hooks';
import { TextArea } from '../Admin/DocumentRequest/TextArea';
import { DeleteConfirmationModal } from './Modal/DeleteConfirmationModal';

export const DocumentDetailsTab = ({
    setDocumentTabOpen,
    setSelectedRowIndex,
    debouncedApiCallFromParent,
    tabData = {}
}) => {
    const scrollBar = useRef(null);

    const [documentRequestInfo, setDocumentRequestInfo] = useState({});
    const [documentUploadInfo, setDocumentUploadInfo] = useState({});
    const [editingNotes, setEditingNotes] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [notes, setNotes] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isScrolling, setIsScrolling] = useState(false);


    const dispatch = useDispatch();
    const isAdmin = useSelector((state) => state.user.isUserAdmin);

    const debouncedApiCall = useDebounce(async () => {
        try {
            const [documentRequestResponse, documentUploadResponse] = await Promise.all([
                getDocumentDetailsByID({ id: tabData.id }),
                getFileUploadsByDocumentRequestID({ documentRequestID: tabData.id }),
            ]).catch((error) => {
                throw error;
            });
            if (documentRequestResponse?.data) setDocumentRequestInfo(documentRequestResponse.data);
            if (documentUploadResponse?.data) setDocumentUploadInfo(documentUploadResponse.data);
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    }, 500);

    const handleNotesSave = async () => {
        try {
            setEditingNotes(false);
            dispatch(setLoaderVisibility(true));
            await updateDocsRequest({ id: tabData.id, body: { notes } });
            debouncedApiCall();
            popup('success', DOC_REQUEST.NOTES_UPDATED);
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    // to get specific document details
    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
        return () => {
            setDocumentRequestInfo({});
            setDocumentUploadInfo({});
            setSelectedFiles([]);
            setFileNames([]);
            setNotes('');
            setEditingNotes(false);
        };
    }, [tabData]);

    useEffect(() => {
        if (documentRequestInfo?.notes) setNotes(startCase(documentRequestInfo?.notes));
    }, [documentRequestInfo]);

    // to handle approve logic
    const handleApproveClick = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            await updateDocsRequest({ id: tabData.id, body: { notes, status: 'approved' } });
            debouncedApiCall();
            debouncedApiCallFromParent();
            popup('success', DOC_REQUEST.DOCUMENT_REQUEST_APPROVED);
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };
    // to handle reject logic
    // TODO
    const handleRejectClick = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            await updateDocsRequest({ id: tabData.id, body: { notes, status: 'rejected' } });
            debouncedApiCall();
            debouncedApiCallFromParent();
            popup('success', DOC_REQUEST.DOCUMENT_REQUEST_REJECTED);
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };
    // to handle delete logic
    // TODO
    const handleDeleteClick = async () => {
        try {
            dispatch(setLoaderVisibility(true));
            await deleteDocsRequest({ id: tabData.id });
            debouncedApiCallFromParent();
            setDocumentTabOpen(false);
            popup('error', DOC_REQUEST.DOCUMENT_REQUEST_DELETED);
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    const onFileChange = (event) => {
        try {
            const files = event.target.files;
            validateFiles(files, selectedFiles);
            const selectedFilesArray = Array.from(files);
            const selectedFileNames = selectedFilesArray.map((file) => file.name); // Get file names
            setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...selectedFilesArray]);
            setFileNames((prevFileNames) => [...prevFileNames, ...selectedFileNames]); // Update file names state
        } catch (error) {
            popup('error', error.message);
        }
    };

    // to handle upload file logic
    const openFileExplorer = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.multiple = true; // Allow multiple file selection
        input.onchange = onFileChange;
        input.click();
    };

    // to handle remove file logic
    const handleRemoveFile = (indexToRemove) => {
        setSelectedFiles((prevSelectedFiles) =>
            prevSelectedFiles.filter((_, index) => index !== indexToRemove),
        );
        setFileNames((prevFileNames) =>
            prevFileNames.filter((_, index) => index !== indexToRemove),
        );
    };

    const readFileAsArrayBuffer = async (file) => {
        try {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            return new Promise((resolve, reject) => {
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const handleFileUpload = async () => {
        try {
            const fileCount = selectedFiles.length;
            dispatch(setLoaderVisibility(true));
            const data = {
                account_id: tabData.account_id,
                doc_req_id: tabData.id,
                objects: fileNames,
            };
            const uploadedFileNames = [];
            const uploadedFilePaths = [];

            // Get signed URL
            const response = await signedURL({ data });
            const urlData = response.data;
            for (let i = 0; i < urlData.length; i++) {
                const binaryData = await readFileAsArrayBuffer(selectedFiles[i]);
                const { preSignedUrl, filePath, objectName } = urlData[i];
                const response = await fetch(preSignedUrl, {
                    method: 'PUT',
                    body: binaryData,
                    headers: {
                        'Content-Type': getContentTypeByExtension(
                            getFileExtensionFromPath(filePath),
                        ),
                    },
                });
                if (!response.ok) {
                    const body = {
                        document_request_id: tabData.id,
                        document_category_id: tabData.document_category_id,
                        file_path: uploadedFilePaths,
                        file_name: uploadedFileNames,
                    };
                    await doFileUploadEntry({ body });
                    throw new Error(DOC_REQUEST.FILE_UPLOAD_FAILED);
                } else {
                    uploadedFileNames.push(objectName);
                    uploadedFilePaths.push(filePath);
                }
            }
            const body = {
                document_request_id: tabData.id,
                document_category_id: tabData.document_category_id,
                file_path: uploadedFilePaths,
                file_name: uploadedFileNames,
            };
            await doFileUploadEntry({ body });
            debouncedApiCall();
            popup(
                'success',
                fileCount > 1
                    ? `${fileCount} ${DOC_REQUEST.DOCS_UPLOADED}`
                    : `${fileCount} ${DOC_REQUEST.DOC_UPLOADED}`,
            );
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    const fetchFileSignedUrl = async ({ filePath }) => {
        try {
            const response = await getFileSignedUrl({ filePath });
            return response.data.url;
        } catch (error) {
            popup('error', error.message);
        }
    };

    function getFileNameFromFilePath(filePath) {
        const parts = filePath.split('/');
        const fileNameWithExtension = parts[parts.length - 1];

        return fileNameWithExtension;
    }

    const handleViewFile = async (filePath) => {
        dispatch(setLoaderVisibility(true));

        fetchFileSignedUrl({ filePath: filePath }).then((signedUrl) => {
            dispatch(setLoaderVisibility(false));
            dispatch(
                setFileViewModalDetails({
                    openFileViewerModal: true,
                    signedURL: signedUrl,
                    fileName: getFileNameFromFilePath(filePath),
                }),
            );
        });
    };

    async function fetchBlobFromSignedUrl(signedURL) {
        try {
            const response = await fetch(signedURL);
            if (!response.ok) {
                throw new Error(DOC_REQUEST.ERROR_FETCHING_FILE);
            }
            const blob = await response.blob();
            return blob;
        } catch (error) {
            return null;
        }
    }

    const handleDownloadFile = async (filePath) => {
        dispatch(setLoaderVisibility(true));
        try {
            const signedURL = await fetchFileSignedUrl({ filePath });
            let blob = await fetchBlobFromSignedUrl(signedURL);
            const blobURL = URL.createObjectURL(blob);

            // Create a temporary anchor element to initiate the download
            const downloadAnchor = document.createElement('a');
            downloadAnchor.href = blobURL;
            downloadAnchor.setAttribute('download', filePath.split('/').pop()); // Set the filename for the downloaded file
            downloadAnchor.style.display = 'none';
            document.body.appendChild(downloadAnchor);

            // Initiate the download
            downloadAnchor.click();

            // Cleanup
            document.body.removeChild(downloadAnchor);
            URL.revokeObjectURL(blobURL); // Revoke the blob URL to release memory
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            popup('error', DOC_REQUEST.ERROR_DOWNLOADING_FILE);
            dispatch(setLoaderVisibility(false));
        }
    };

    const UploadDocumentSection = () => {
        return (
            <>
                <div className='doc-upload-add'>
                    <div className='filled-doc d-flex flex-wrap align-items-center justify-content-start'>
                        {selectedFiles.map((file) => {
                            return (
                                <div
                                    key={file.name}
                                    className='d-flex flex-column align-items-start justify-content-start'
                                >
                                    <div className='doc-hov position-relative'>
                                        <div className=' position-relative'>
                                            <DocImage />
                                        </div>
                                        <ButtonComponent
                                            className='cross-icon border-0 p-0 background-color-transparent'
                                            type='button'
                                            clickHandler={() =>
                                                handleRemoveFile(selectedFiles.indexOf(file))
                                            }
                                        >
                                            <FeedbackCross />
                                        </ButtonComponent>
                                        {file.name.length < 10 ? (
                                            <span>{file.name}</span>
                                        ) : (
                                            <span className='truncate-text'>
                                                {file.name.slice(0, 10) + '...'}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        <ButtonComponent
                            disabled={selectedFiles.length >= 5}
                            className={`p-0 add-btn-area d-flex align-items-center justify-content-center ssc-primary-green-btn ${selectedFiles.length >= 5 ? 'disabled' : ''}`}
                            type='button'
                            clickHandler={openFileExplorer}
                        >
                            <IconPlus />
                        </ButtonComponent>
                    </div>
                </div>

                {selectedFiles.length ? (
                    <ButtonComponent
                        className='upload-file w-auto upload-btn d-flex align-items-center justify-content-center ssc-primary-green-btn'
                        clickHandler={handleFileUpload}
                    >
                        {selectedFiles.length > 1 ? DOCUMENTS.UPLOAD_FILES : DOCUMENTS.UPLOAD_FILE}
                    </ButtonComponent>
                ) : null}
            </>
        );
    };

    const UploadedDocumentSection = ({ fileData, canShowAddButton = false }) => {
        return (
            <>
                <div className='filled-doc flex-wrap d-flex align-items-start justify-content-start'>
                    {fileData &&
                        fileData?.length > 0 &&
                        fileData.map((file) => {
                            return (
                                <div
                                    key={file.id}
                                    className='d-flex flex-column align-items-start justify-content-start'
                                >
                                    <div className='d-flex flex-column'>
                                        <p className='timing mb-0'>
                                            {convertTimestampToDate(file.uploaded_at).formattedDate}
                                        </p>
                                        <p className='timing mb-0'>
                                            {convertTimestampToDate(file.uploaded_at).formattedTime}
                                        </p>
                                        <div className='doc-hov position-relative mt-1'>
                                            <div className='position-relative'>
                                                <DocImage
                                                    className='doc-img '
                                                    width={67}
                                                    height={61}
                                                />
                                            </div>
                                            <ButtonComponent
                                                className='icon1 border-0 p-0 background-color-transparent'
                                                type='button'
                                                clickHandler={() => {
                                                    handleDownloadFile(file.file_path);
                                                }}
                                            >
                                                <IconHover1 height={16} width={16} />
                                            </ButtonComponent>
                                            <ButtonComponent
                                                className={
                                                    'icon2 border-0 p-0 background-color-transparent'
                                                }
                                                type='button'
                                                clickHandler={() => handleViewFile(file.file_path)}
                                            >
                                                <IconHover2 height={16} width={16} />
                                            </ButtonComponent>
                                        </div>
                                        {file.file_name.length < 10 ? (
                                            <span
                                                className={`${file.file_name.length < 8} ? text-center : '`}
                                            >
                                                {file.file_name}
                                            </span>
                                        ) : (
                                            <span className='truncate-text'>
                                                {file.file_name.slice(0, 9) +
                                                    '...' +
                                                    getFileExtension(file.file_name)}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    {documentRequestInfo?.status === 'rejected' && canShowAddButton && (
                        <span className={selectedFiles.length ? '' : 'mt-5'}>
                            {selectedFiles.length && documentUploadInfo?.user_upload_count > 3 ? (
                                <hr />
                            ) : null}
                            <UploadDocumentSection />
                        </span>
                    )}
                </div>
            </>
        );
    };

    const RenderDocumentSection = () => {
        const determineHeading = () => {
            if (isAdmin) return DOCUMENTS.UPLOADED_BY_USER;
            else if (!isAdmin && !documentUploadInfo.user_upload_count)
                return DOCUMENTS.UPLOAD_DOCUMENTS;
            else if (!isAdmin && documentUploadInfo.user_upload_count)
                return DOCUMENTS.UPLOADED_DOCUMENTS;
        };

        const determineComponent = () => {
            if (isAdmin && documentUploadInfo.user_upload_count) {
                return (
                    <UploadedDocumentSection
                        showDate={!isAdmin}
                        fileData={documentUploadInfo.uploaded_by_user}
                    />
                );
            } else if (isAdmin && !documentUploadInfo.user_upload_count) {
                return <p>Nothing is uploaded</p>;
            } else if (!isAdmin && documentUploadInfo.user_upload_count) {
                return (
                    <UploadedDocumentSection
                        showDate={!isAdmin}
                        fileData={documentUploadInfo.uploaded_by_user}
                        canShowAddButton={true}
                    />
                );
            } else if (!isAdmin && !documentUploadInfo.user_upload_count) {
                return <UploadDocumentSection />;
            }
        };

        const heading = determineHeading();
        const component = determineComponent();

        return (
            <>
                <p className='title mb-3'>{heading}</p>
                <div>
                    <div className='doc border-0'>{component}</div>
                </div>
            </>
        );
    };

    const RenderDocumentCSMSection = () => {
        const determineHeading = () => {
            if (isAdmin && documentUploadInfo.csm_upload_count) return DOCUMENTS.UPLOADED_DOCUMENTS;
            else if (isAdmin && !documentUploadInfo.csm_upload_count)
                return DOCUMENTS.UPLOAD_DOCUMENTS;
            else if (!isAdmin) return DOCUMENTS.UPLOADED_BY_CSM;
        };

        const determineComponent = () => {
            if (isAdmin && documentUploadInfo.csm_upload_count) {
                return <UploadedDocumentSection fileData={documentUploadInfo.uploaded_by_csm} />;
            } else if (isAdmin && !documentUploadInfo.csm_upload_count) {
                return <UploadDocumentSection />;
            } else if (!isAdmin && documentUploadInfo.csm_upload_count) {
                return <UploadedDocumentSection fileData={documentUploadInfo.uploaded_by_csm} />;
            } else if (!isAdmin && !documentUploadInfo.csm_upload_count) {
                return <p> Nothing is uploaded</p>;
            }
        };
        const component = determineComponent();
        const heading = determineHeading();

        return (
            <>
                <p className='title mb-3'>{heading}</p>
                <div>
                    <div className='doc border-0'>{component}</div>
                </div>
            </>
        );
    };

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };


    return (
        <>
            <div className='tab-open-area show'>
                <div className='position-relative ps-3'>
                    <button
                        className='close-btn bg-white'
                        onClick={() => {
                            setDocumentTabOpen(false);
                            setSelectedRowIndex(0);
                        }}
                    >
                        <BlueArrow />
                    </button>
                    <div onScroll={handleScrolling} className={`position-relative content-area px-4 py-3 ${isScrolling ? 'scrolling' : 'not-scrolling'}`}>
                        {isAdmin &&
                            !isEmpty(documentUploadInfo) &&
                            documentUploadInfo.uploaded_by_user.length > 0 && documentRequestInfo.status === 'pending' && (
                                <div className='approve-div-btn'>
                                    <button
                                        className='ssc-secondary-white-btn'
                                        type='button'
                                        onClick={handleApproveClick}
                                    >
                                        {DOCUMENTS.APPROVE}
                                    </button>
                                    <button
                                        className='ssc-secondary-red-btn'
                                        type='button'
                                        onClick={handleRejectClick}
                                    >
                                        {DOCUMENTS.REJECT}
                                    </button>
                                </div>
                            )}
                        {isAdmin && (
                            <div className='delete-icon'>
                                <button
                                    className='border-0 p-0 bg-white'
                                    onClick={() => setIsDeleteModalOpen(true)}
                                >
                                    <Delete />
                                </button>
                            </div>
                        )}
                        <div className='row'>
                            <h6>Document</h6>
                            <div className='col-6 left-side'>
                                <div className='documents-area'>
                                    <RenderDocumentSection />
                                </div>
                                <div className='documents-area'>
                                    <RenderDocumentCSMSection />
                                </div>
                            </div>
                            <div className='col-6 right-side'>
                                <div className='d-flex align-items-start flex-column detail-area'>
                                    <div className='priority-set'>
                                        <p className='title'>{DOCUMENTS.PRIORITY}</p>
                                        <div className='text d-flex align-items-center'>
                                            <div
                                                className={`priority ${documentRequestInfo.priority}-priority`}
                                            >
                                                {capitalize(documentRequestInfo.priority)}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className='title'>{DOCUMENTS.CUSTOMER}</p>
                                        <div className='text d-flex align-items-center'>
                                            {documentRequestInfo?.account_name
                                                ? startCase(documentRequestInfo?.account_name)
                                                : DOC_REQUEST.NOT_ASSIGNED}
                                        </div>
                                    </div>
                                    <div>
                                        <p className='title'>{DOCUMENTS.CSM}</p>
                                        <div className='text d-flex align-items-center'>
                                            <ImageWithFallback
                                                src={tabData?.csm_image}
                                                alt='user-img'
                                                width={'24'}
                                                height={'24'}
                                            />
                                            {documentRequestInfo?.csm_name
                                                ? documentRequestInfo?.csm_name
                                                : DOC_REQUEST.NOT_ASSIGNED}
                                        </div>
                                    </div>
                                    <div>
                                        <p className='title'>{DOCUMENTS.HIRING_CLIENT}</p>
                                        <div className='text d-flex align-items-center'>
                                            {documentRequestInfo?.hiring_client_name
                                                ? startCase(documentRequestInfo?.hiring_client_name)
                                                : DOC_REQUEST.NOT_ASSIGNED}
                                        </div>
                                    </div>
                                    <div>
                                        <p className='title'>{DOCUMENTS.PLATFORM}</p>
                                        <div className='text d-flex align-items-center'>
                                            {documentRequestInfo?.platform_name
                                                ? documentRequestInfo?.platform_name
                                                : DOC_REQUEST.NOT_ASSIGNED}<sup>{SYMBOL.REGISTERED}</sup>
                                        </div>
                                    </div>
                                    <div>
                                        <p className='title'>{DOCUMENTS.STATUS}</p>
                                        <div className='status-set d-flex align-items-center'>
                                            <div className={`status ${tabData.status}-status`}>
                                                {documentRequestInfo?.status
                                                    ? startCase(documentRequestInfo?.status)
                                                    : DOC_REQUEST.NOT_ASSIGNED}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-100'>
                                        <span className='d-flex align-items-center'>
                                            <p className='title'>{DOCUMENTS.NOTES}</p>
                                            {isAdmin && (
                                                <ButtonComponent
                                                    className={`background-color-transparent ${editingNotes ? 'save-notes-button mb-0 px-2' : 'border-0 p-0'}`}
                                                    type='button'
                                                    clickHandler={() => {
                                                        !editingNotes
                                                            ? setEditingNotes(true)
                                                            : handleNotesSave();
                                                    }}
                                                >
                                                    {editingNotes ? (
                                                        'save'
                                                    ) : !documentRequestInfo?.notes ||
                                                      documentRequestInfo?.notes === '' ? (
                                                        <GreenPlus className={'ms-1 mb-2'} />
                                                    ) : (
                                                        <Edit className={'ms-1 mb-2'} />
                                                    )}
                                                </ButtonComponent>
                                            )}

                                            {isAdmin && editingNotes && (
                                                <ButtonComponent
                                                    className={`background-color-transparent  btn-close border-0 p-0 ms-2 mb-2`}
                                                    type='button'
                                                    clickHandler={() => {
                                                        setEditingNotes(false);
                                                        setNotes(
                                                            startCase(documentRequestInfo?.notes),
                                                        );
                                                    }}
                                                />
                                            )}
                                        </span>
                                        <div className='text d-flex align-items-center w-100'>
                                            {editingNotes ? (
                                                <TextArea
                                                    value={notes}
                                                    note={notes}
                                                    setNote={setNotes}
                                                    placeholder='Add Notes'
                                                    className={'notes-textarea w-100 p-2'}
                                                    rows={7}
                                                    maxLength={MAX_LENGTH.DOCUMENT_NOTES}
                                                />
                                            ) : documentRequestInfo?.notes ? (
                                                notes
                                            ) : (
                                                DOC_REQUEST.NO_NOTES_CREATED
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isDeleteModalOpen && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModalOpen}
                    setIsDeleteModal={setIsDeleteModalOpen}
                    selectedItemId={tabData.id}
                    handleDeleteClick={handleDeleteClick}
                    deleteButtonName={DOC_REQUEST.DELETE_BUTTON}
                />
            )}
        </>
    );
};

// define prop types
DocumentDetailsTab.propTypes = {
    setDocumentTabOpen: PropTypes.func,
    setSelectedRowIndex: PropTypes.func,
    debouncedApiCallFromParent: PropTypes.func,
    tabData: PropTypes.object
};
