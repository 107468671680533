import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from 'lodash';

import { useDebounce } from '../../../hooks';
import { EmptyNotificationPage, DownArrow } from "../../../assets/svg";
import { setLoaderVisibility, setNavigationState, setNotificationUpdate } from '../../../redux';
import { LOCAL_STORAGE, NOTIFICATION, ROLES, ROUTES, SUBSCRIPTION_EXPIRY } from '../../../constants';
import { getAllNotificationsByAccountFilters, putMarkAsReadNotification, putMarkAllAsReadByNotificationType, popup, getLocalStorageItem, createSubscriptionExpiryObject, getSubscriptionStatus, getAccountDetailsFromAccountArrayByAccountId, convertTimestampToDate } from "../../../utils";

export const Notification = () => {

    // declarations
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);
    const selectedAccount = useSelector((state) => state.accountArray.selectedAccount) || '';
    const isNotificationSyncRequired = useSelector(state => state.notificationUpdate);
    const [currentNotificationRoute, setCurrentNotificationRoute] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState(0);
    const [notificationTabsObj, setNotificationTabsObj] = useState({});
    const [notificationTabsArr, setNotificationTabsArr] = useState([]);
    const [currentNotificationTab, setCurrentNotificationTab] = useState('');
    const [isReadFilter, setIsReadFilter] = useState(false);
    const [scrollTo, setScrollTo] = useState({});
    const [blinkingNotification, setBlinkingNotification] = useState('');
    const [subscriptionExpiryMessage, setSubscriptionExpiryMessage] = useState('');
    const [selectedAccountDetails, setSelectedAccountDetails] = useState(null);
    const accounts = useSelector(state => state.accountArray.accounts);
    const accountStates = useSelector(state => state.isSubscriptionExpiryRead.accountStates);
    const subscriptionExpiryObj = createSubscriptionExpiryObject(accounts);
    const [expandContent, setExpandContent] = useState({});

    const { navigationState } = useSelector((state) => state.navigationState);
    // functions

    const handleTabExpand = (notification) => {
        if(!notification.is_read && notification?.type !== 'regulatory_issue' && notification?.type !== 'subscription_expiry') {
            handleMarkAsRead(notification.id);
        }

        setExpandContent(prevState => ({
            ...prevState,
            [notification.id]: !prevState[notification.id]
        }));
    }

    const handleRedirect = (notification) => {
        if(notification?.type !== 'regulatory_issue' && notification?.type !== 'subscription_expiry') {
            if (!notification?.is_read) {
                handleMarkAsRead(notification.id);
            }

            if (userRole === ROLES.SECONDARY_USER || userRole === ROLES.PRIMARY_USER) {
                if (notification.type.startsWith('document')) navigate(ROUTES.CUSTOMER.DOCUMENTS);
                if (notification.type.startsWith('scorecard') ||  notification.type.startsWith('hiring_client_grade')) navigate(ROUTES.CUSTOMER.SCORE_CARD_REPORT);
            } else {
                if (notification.type.startsWith('account')) navigate(ROUTES.ADMIN.CUSTOMER);
            }
        } else {
            handleTabExpand(notification);
        }
    }

    const handleTabClick = (tab) => {
        if(navigationState) handleViewAllClick();
        if (tab !== 'subscription_expiry') {
            setCurrentNotificationTab(tab);
            setScrollTo({});
            if (currentNotificationTab !== tab) setIsReadFilter('');
        } else {
            setCurrentNotificationTab(tab);
        }
    };

    const handleReadUnreadFilterSelect = (event) => {
        event.preventDefault();
        const selectedValue = event.target.value;
        setIsReadFilter(selectedValue);
    };

    const handleMarkAsRead = (notificationId) => {
        putMarkAsReadNotification({ notificationId: notificationId, accountId: selectedAccount || ''})
            .then((res)=> {
                if (res.status === 'success') {
                    dispatch(setNotificationUpdate(prev => !prev));
                    debouncedApiCall();
                }
            }).catch((error) => {
                popup('error', error.message);
            });
    };

    const handleMarkAllAsReadClick = () => {
        if(newNotifications !== 0) {
            dispatch(setLoaderVisibility(true));
            putMarkAllAsReadByNotificationType({accountId: selectedAccount || '', notificationType: currentNotificationTab})
                .then((res)=> {
                    if (res.status === 'success') {
                        dispatch(setNotificationUpdate(prev => !prev));
                        debouncedApiCall();
                    }
                }).catch((error) => {
                    dispatch(setLoaderVisibility(false));
                    popup('error', error.message);
                });
        }
    };

    const handleViewAllClick = () => {
        dispatch(setNavigationState(null));
        setBlinkingNotification('');
        setExpandContent({});
    };

    const handleScroll = useDebounce((scrollTo) => {
        const element = document.getElementById(scrollTo.id);

        if (element) {
            containerRef.current.scrollTop = element.offsetTop - containerRef.current.offsetTop;
        }

        const delay = 1650;

        setTimeout(() => {
            setBlinkingNotification((prev) => ({
                ...prev,
                [scrollTo.id]: false,
            }));
        }, delay);

        setBlinkingNotification((prev) => ({
            ...prev,
            [scrollTo.id]: true,
        }));
    });

    const debouncedApiCall = useDebounce(() => {
        const filter = {
            type: currentNotificationTab,
        };
        if(navigationState && navigationState?.id) {
            filter.id = navigationState.id
            currentNotificationTab !== 'regulatory_issue' && delete filter.type;
        }

        if (isReadFilter && isReadFilter !== undefined && isReadFilter !== null && isReadFilter !== '') {
            filter.is_read = isReadFilter && currentNotificationTab !== 'regulatory_issue' ? isReadFilter : ''
        }

        getAllNotificationsByAccountFilters({
            include_all_notifications: false,
            accountId: selectedAccount || '',
            filter: !isEmpty(filter) ? filter : ''
        }).then((res)=>{
            if (res?.data) {
                if (navigationState && navigationState.id && currentNotificationTab === 'regulatory_issue') {
                    const filteredData = res?.data.filter((item) => item?.id === navigationState?.id);
                    setNotifications(filteredData);
                } else {
                    setNotifications(res.data);
                }
                setNewNotifications(res?.new_notifications || 0);
            }

            dispatch(setLoaderVisibility(false));

            if (Object.keys(scrollTo).length) {
                handleScroll(scrollTo);
            }
        })
        .catch((error) => {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        });
    }, 500);

    // initial state

    useEffect(() => {
        setCurrentNotificationRoute(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        if (!currentNotificationTab) {
            if (userRole === ROLES.ADMIN || userRole === ROLES.CSM){
                setCurrentNotificationTab(userRole !== ROLES.ADMIN ? 'regulatory_issue' : 'account');
            } else {
                setCurrentNotificationTab('regulatory_issue');
            }
        }

        if (currentNotificationRoute === ROUTES.ADMIN.NOTIFICATION) {
            if(userRole === 'admin') {
                setNotificationTabsObj(NOTIFICATION.NOTIFICATION_TABS.ADMIN);
                setNotificationTabsArr(Object.keys(NOTIFICATION.NOTIFICATION_TABS.ADMIN));
            }
            else if(userRole === 'csm') {
                setNotificationTabsObj(NOTIFICATION.NOTIFICATION_TABS.CSM);
                setNotificationTabsArr(Object.keys(NOTIFICATION.NOTIFICATION_TABS.CSM));
            }
        } else {
            const _selectedAccountDetails = getAccountDetailsFromAccountArrayByAccountId(accounts, selectedAccount);
            setSelectedAccountDetails(_selectedAccountDetails);

            if (accountStates?.[selectedAccount] || _selectedAccountDetails?.is_subscription_expiry_read) {
                const customerNotifications = {
                    ...NOTIFICATION.NOTIFICATION_TABS.CUSTOMER,
                    subscription_expiry: SUBSCRIPTION_EXPIRY.SUBSCRIPTION_EXPIRY_NOTIFICATION_TAB
                };

                setNotificationTabsObj(customerNotifications);
                setNotificationTabsArr(Object.keys(customerNotifications));

                const expiryDetails = subscriptionExpiryObj[selectedAccount];
                let message = getSubscriptionStatus(expiryDetails?.subscription_expiry);

                message = {
                    id: 'subscription_expiry',
                    is_read: false,
                    type: 'subscription_expiry',
                    ...message
                };

                setSubscriptionExpiryMessage(message);
            } else {
                if (currentNotificationTab === 'subscription_expiry') {
                    setCurrentNotificationTab('regulatory_issue');
                }

                setNotificationTabsObj(NOTIFICATION.NOTIFICATION_TABS.CUSTOMER);
                setNotificationTabsArr(Object.keys(NOTIFICATION.NOTIFICATION_TABS.CUSTOMER));
            }
        }
    }, [currentNotificationRoute, selectedAccount, accountStates?.[selectedAccount]]);

    useEffect(() => {
        if (currentNotificationTab !== 'subscription_expiry') {
            dispatch(setLoaderVisibility(true));
            debouncedApiCall();
        }
    }, [scrollTo, isReadFilter, currentNotificationTab, selectedAccount, navigationState]);

    useEffect(() => {
        debouncedApiCall();
    }, [isNotificationSyncRequired]);

    useEffect(() => {
        if (navigationState) {
            const notification = {
                id: navigationState.id,
                type: navigationState.type,
            };
            handleTabExpand(notification);
            if (navigationState.type !== 'subscription_expiry') setScrollTo(navigationState);
            setCurrentNotificationTab(navigationState.type);
        }
        else{
            setScrollTo({});
        }
        return () => {
            if(navigationState && navigationState?.id){
                setBlinkingNotification('');
            }
        }
    }, [navigationState]);

    return (
        <div className="notification-title">
            <div className="p-3 notification-title d-flex align-items-end justify-content-between">
                <div>
                    <div className="d-flex align-items-center mb-3">
                        <h5 className="mb-0">
                            {NOTIFICATION.HEADING}
                        </h5>
                        {newNotifications !== 0 &&
                            <span className="d-flex align-items-center justify-content-center">
                            {newNotifications} NEW
                            </span>
                        }
                    </div>
                    <button className={"mb-0 link-text notification-mark-all-as-read button" + (newNotifications !== 0 || navigationState && navigationState.id ? "" : " disabled")} onClick={navigationState ? handleViewAllClick :  handleMarkAllAsReadClick}>
                        {navigationState && navigationState.id? NOTIFICATION.VIEW_ALL : NOTIFICATION.MARK_ALL_READ}
                    </button>
                </div>
                <div className="tabs-filters d-flex align-items-center justify-content-end">
                    {notificationTabsArr.length > 0 &&
                        notificationTabsArr.map((notificationTab, index) => {
                            const documentTab =  currentNotificationTab.includes('document');
                            const hiringClientGradeTab =  currentNotificationTab.includes('hiring_client_grade');
                            const isTabActive = notificationTab === currentNotificationTab || (documentTab && notificationTab === 'document') || (hiringClientGradeTab && notificationTab === 'scorecard_created');
                            return (
                                <button
                                    key={index}
                                    className={"tab-btn border-0 bg-white position-relative" + (isTabActive ? " active" : "")}
                                    onClick={() => handleTabClick(notificationTab)}
                                >
                                    {notificationTabsObj[notificationTab]}
                                </button>
                            )
                        })
                    }

                    {!navigationState && <select name="" id="" className={"filter-btn" + (currentNotificationTab === 'regulatory_issue' && currentNotificationTab === 'subscription_expiry' ? " disabled" : "") } onChange={handleReadUnreadFilterSelect} disabled={currentNotificationTab === 'regulatory_issue' || currentNotificationTab === 'subscription_expiry'} value={currentNotificationTab === 'regulatory_issue' ? '' : isReadFilter}>
                        <option value={''}>{NOTIFICATION.FILTER_STRINGS.ALL}</option>
                        <option value={0}>{NOTIFICATION.FILTER_STRINGS.ALL_UNREAD}</option>
                        <option value={1}>{NOTIFICATION.FILTER_STRINGS.ALL_READ}</option>
                    </select> }
                </div>
            </div>
            <div className="mt-2">
                <div className="notification-list" ref={containerRef}>
                    {notifications.length > 0 && currentNotificationTab !== 'subscription_expiry' ?  (notifications.map((notification) => (
                            <div
                                key={notification.id}
                                id={notification.id}
                                className={"p-3 w-100 notification-text page-text d-flex align-items-start justify-content-between border-bottom" + ((!notification.is_read && notification.type !== 'regulatory_issue') ? " new-notification" : "") + (blinkingNotification[notification.id] ? " blink-notification" : "")}
                                onClick={() => handleRedirect(notification)}
                            >
                                <div className='w-100 pe-3'>
                                    <div className="d-flex justify-content-between">
                                          <div>
                                              <h5 className='title'>{notification.title}</h5>
                                              <p className='small fst-italic margin-top-7'>
                                                  {`${convertTimestampToDate(notification.created_at).formattedDate} 
                                                             ${convertTimestampToDate(notification.created_at).formattedTime}`}
                                              </p>
                                          </div>
                                        <button className="d-flex align-items-center justify-content-center notification-drop-icon-spacing background-color-transparent border-none" onClick={(e) => {
                                            e.stopPropagation();
                                            handleTabExpand(notification);
                                        }}>
                                            {expandContent[notification.id] ? <DownArrow rotate={true}/> : <DownArrow/>}
                                        </button>
                                    </div>
                                    <p className={`notification-txt mb-0 ${expandContent[notification.id] ? 'notification-txt-expand' : ''}`}>{notification.content}</p>
                                </div>
                            </div>
                        ))
                    ) : ( currentNotificationTab !== 'subscription_expiry' &&
                        <div className='d-flex align-items-center justify-content-center h-50 my-4 flex-column'>
                            <EmptyNotificationPage />
                            <p className='mb-0 mt-2'>{NOTIFICATION.NO_NOTIFICATIONS}</p>
                        </div>
                    )}
                    {
                        (selectedAccountDetails?.is_subscription_expiry_read || accountStates?.[selectedAccount]) && currentNotificationTab === 'subscription_expiry' &&
                        <div className="p-3 w-100 notification-text page-text d-flex align-items-start justify-content-between" onClick={() => handleTabExpand(subscriptionExpiryMessage)}>
                            <div className='w-100 pe-3'>
                                <div className="d-flex justify-content-between">
                                    <h5 className='title'>{subscriptionExpiryMessage.title}</h5>
                                    <button className="d-flex align-items-center justify-content-center notification-drop-icon-spacing background-color-transparent border-none">
                                        {expandContent[subscriptionExpiryMessage.id] ? <DownArrow rotate={true}/> : <DownArrow/>}
                                    </button>
                                </div>
                                <p className={`notification-txt mb-0 ${expandContent[subscriptionExpiryMessage.id] ? 'notification-txt-expand' : ''}`}>{subscriptionExpiryMessage.content}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}
