import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Edit } from '../../../assets/svg';
import { getLocalStorageItem } from '../../../utils';
import { LOCAL_STORAGE, ROLES } from '../../../constants';

export const EditButton = ({ id, navigateTo }) => {
    const navigate = useNavigate();
    const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (userRole === ROLES.ADMIN) {
            setShowButton(true);
        }
    }, [userRole]);

    return showButton ? (
        <button
            className='border-0 p-0 bg-white'
            onClick={() => {
                navigate(navigateTo, {
                    state: {
                        id: id,
                    },
                });
            }}
        >
            <Edit />
        </button>
    ) : '-'
};
EditButton.propTypes = {
    id: PropTypes.string,
    navigateTo: PropTypes.string,
};
