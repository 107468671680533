import React from 'react';
import PropTypes from 'prop-types';

const UploadIcon = ({width, height}) => (
    <svg width={width || 21} height={height || 21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.38948 7.98403H4.45648C2.42148 7.98403 0.771484 9.63403 0.771484 11.669L0.771484 16.544C0.771484 18.578 2.42148 20.228 4.45648 20.228H15.5865C17.6215 20.228 19.2715 18.578 19.2715 16.544V11.659C19.2715 9.63003 17.6265 7.98403 15.5975 7.98403L14.6545 7.98403" stroke="#738DA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0215 1.19057V13.2316" stroke="#738DA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.1062 4.11877L10.0212 1.19077L12.9372 4.11877" stroke="#738DA6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

UploadIcon.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
};

export default UploadIcon;
