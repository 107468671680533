export const MAX_LENGTH = {
    PASSWORD_FIELD: 16,
    PHONE: 10,
    EMAIL: 64,
    NAME: 40,
    ACCOUNT_NAME: 255,
    DOCUMENT_NOTES: 255,
    HIRING_CLIENT_GRADE: 255,
};

export const NAVIGATION_DATA_TYPES = {
    PLATFORM: 'platform',
    ACCOUNT: 'account',
};

export const NOT_ALLOWED_FILE_TYPES = [
    // Executable files
    'application/x-msdownload', // .exe
    'application/javascript', // .js
    'application/x-msdos-program', // .com
    'application/x-msi', // .msi
    'application/x-sh', // .sh
    'application/x-executable', // .bin
    'application/x-dosexec', // .bat
    'application/x-java-jnlp-file', // .jnlp
    'application/x-php', // .php
    'application/x-java-archive', // .jar
    'application/x-sharedlib', // .so
    'application/x-object', // .o
    'application/x-bytecode.python', // .pyc
    'application/x-perl', // .pl
    'application/x-ruby', // .rb
    'application/x-shellscript', // .sh
    'application/x-sql', // .sql
    'application/x-diskcopy', // .img
    'application/octet-stream', // .bin
    'application/x-msdos-program', // .dll
    'application/x-winexe', // .dll
    'application/x-msmetafile', // .dll
    'application/x-ms-shortcut', // .lnk
    'application/x-sys', // .sys

    // Archive and compressed files
    'application/zip', // .zip
    'application/x-zip-compressed', // .zip
    'application/x-gzip', // .gz
    'application/x-gzip-compressed', // .gzip
    'application/x-lzop', // .lzo
    'application/x-lzma', // .lzma
    'application/x-lzma-compressed-tar', // .tar.lzma
    'application/x-lzma-compressed', // .lzma
    'application/x-lzip', // .lz
    'application/x-lzip-compressed-tar', // .tar.lz
    'application/x-lzip-compressed', // .lzip
    'application/x-lz4', // .lz4
    'application/x-lz4-compressed', // .lz4
    'application/x-snappy-framed', // .sz
    'application/x-compress', // .Z
    'application/x-ace-compressed', // .ace
    'application/x-bzip', // .bz
    'application/x-bzip2', // .bz2
    'application/x-cab-compressed', // .cab
    'application/x-cbr', // .cbr
    'application/x-rar-compressed', // .rar
    'application/x-compressed', // .tgz
    'application/x-7z-compressed', // .7z
    'application/x-gtar', // .gtar
    'application/x-tgz', // .tgz
    'application/x-arj', // .arj
    'application/x-b1', // .b1
    'application/x-bat', // .bat
    'application/x-cfs-compressed', // .cfs
    'application/x-compactpro', // .cpt
    'application/x-dar', // .dar
    'application/x-dgc-compressed', // .dgc
    'application/x-dms', // .dms
    'application/x-gca-compressed', // .gca
    'application/x-lbr', // .lbr
    'application/x-ns-proxy-autoconfig', // .pac
    'application/x-rpm', // .rpm
    'application/x-sda', // .sda
    'application/x-sea', // .sea
    'application/x-shar', // .shar
    'application/x-sit', // .sit
    'application/x-squashfs', // .squashfs
    'application/x-stuffit', // .sit
    'application/x-stuffitx', // .sitx
    'application/x-tex', // .tex
    'application/x-tex-tfm', // .tfm
    'application/x-texinfo', // .texinfo
    'application/x-tex-gf', // .gf
    'application/x-tcl', // .tcl
    'application/x-webarchive', // .webarchive
    'application/x-webarchive-xml', // .webarchive
    'application/x-xar', // .xar
    'application/x-zoo', // .zoo
    'application/x-rar', // .rar
    'application/x-tar', // .tar
    'application/x-tar-gz', // .tar.gz
    'application/x-tar', // .tar

    // Various others
    'application/vnd.android.package-archive', // .apk
    'application/x-blorb', // .blb
    'application/x-google-chrome-extension', // .crx
    'application/x-web-app-manifest+json', // .webapp
    'application/x-x509-ca-cert', // .cer
    'application/x-xpinstall', // .xpi
    'application/octet-stream', // .bin
    'application/x-debian-package', // .deb
    'application/x-lzh', // .lzh
    'application/x-lzx', // .lzx
    'application/x-trash', // .bak
    'application/x-trash', // .old
    'application/x-trash', // .sik
    'application/x-trash', // .trash
    'application/x-trash', // .bak
    'application/x-trash', // .old
    'application/x-trash', // .sik
    'application/x-trash', // .trash
    'application/x-vdisk', // .vdi
    'application/x-wais-source', // .src
    'application/x-zmachine', // .z1
    'application/x-srplugin', // .srplugin
    'application/x-ruby', // .rb
    'application/x-java-keystore', // .keystore
    'application/x-koan', // .skp
    'application/x-freearc', // .arc
    'application/x-object', // .o
    'application/x-pkcs7-certreqresp', // .p7r
    'application/x-pkcs12', // .p12
    'application/x-pkcs7-certificates', // .p7b
    'application/x-pkcs7-crl', // .crl
    'application/x-x509-ca-cert', // .crt
    'application/x-x509-user-cert', // .crt
    'application/x-x509-email-cert', // .cer
    'application/x-xcf', // .xcf
    'application/x-xfig', // .fig
    'application/x-xz', // .xz
    'application/x-zip', // .zip
    'application/x-zip-compressed', // .zip
];
