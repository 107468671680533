import { React, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { SearchIcon, DownArrow } from '../../../assets/svg';
import { registerAccount, popup, getAllCSM, formValidator } from '../../../utils';
import { REGEX, VALIDATION, MAX_LENGTH, AUTH, CUSTOMER_LIST, DOCUMENTS, CSM, MODAL, BUTTONS, SOURCE } from '../../../constants';
import { useDebounce } from '../../../hooks';

export const AddNewCustomer = ({ showAddModal, setShowAddModal, debouncedApiCall }) => {
    const [csm, setCsm] = useState([]);
    const [selectCsm, setSelectCsm] = useState({ id:'', name:'' });
    const [openCsmDropdown, setOpenCsmDropdown] = useState(false);
    const [searchCsm, setSearchCsm] = useState('');
    const [isFormFocused, setIsFormFocused] = useState(false);
    const dropdownRef = useRef(null);

    const validationSchema = {
        customerName: {
            regex: REGEX.ACCOUNT_NAME,
            message: VALIDATION.CUSTOMER_NAME,
            requiredMessage: VALIDATION.CUSTOMER_NAME_REQUIRED,
        },
        phone: {
            regex: REGEX.PHONE,
            message: VALIDATION.INVALID_PHONE,
            isNotRequired: true,
        },
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            requiredMessage: VALIDATION.EMAIL_REQUIRED,
        },
    };

    const handleSearchCsm = (e) => {
        setSearchCsm(e.target.value);
    };

    const debouncedFetchData = useDebounce(async () => {
        getAllCSM({ limit: 10, offset: 1, search: searchCsm })
            .then((res) => {
                if (res?.data) setCsm(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedFetchData();
    }, [searchCsm]);

    const handleClose = (data) => {
        setOpenCsmDropdown(!openCsmDropdown);
        setSelectCsm({id:data.id, name:data.name})
    }

    const validateForm = () => setIsFormFocused(true);

    const handleClickOutside = useCallback((event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenCsmDropdown(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClear = (e) => {
        e.stopPropagation();
        setSelectCsm('');
    };
    return (
        <Formik
            initialValues={{
                customerName: '',
                phone: '',
                email: '',
            }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema });
                return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
                registerAccount({
                    body: {
                        name: values.customerName,
                        ...(values.phone && { phone: values.phone }),
                        email: values.email,
                        ...(selectCsm.id && { csm_id: selectCsm.id }),
                        source: SOURCE.ADMIN
                    },
                })
                    .then((response) => {
                        setSubmitting(false);
                        setShowAddModal(false);
                        debouncedApiCall();
                        popup('success', response.message);
                    })
                    .catch((error) => {
                        setSubmitting(false);
                        popup('error', error.message);
                    });
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
            }) => (
                <div
                    className={`modal custom-modal-doc-cato fade ${showAddModal ? 'show' : ''}`}
                    id='exampleModal-add'
                    tabIndex={-1}
                    aria-labelledby='exampleModalLabel'
                    aria-hidden={!showAddModal}
                    style={{ display: showAddModal ? 'block' : 'none' }}
                >
                    <div className='modal-dialog w-100 custom-wid '>
                        <div
                            className={`modal-content border-0 ${openCsmDropdown ? 'custom-wid-customer' : ''}`}
                        >
                            <div className='d-flex align-items-center justify-content-between'>
                                <span className='head-title-edit'>
                                    {CUSTOMER_LIST.ADD_CUSTOMER}
                                </span>
                                <button
                                    type='button'
                                    className='btn-close'
                                    data-bs-dismiss='modal'
                                    aria-label='Close'
                                    onClick={() => {
                                        setShowAddModal(false);
                                    }}
                                />
                            </div>
                            <div
                                className='modal-body customer-form p-0'
                                onFocus={() => validateForm()}
                            >
                                <form action='' className='customer-from-content'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='input-set-area mb-4'>
                                                <label htmlFor='customerName'>
                                                    {CUSTOMER_LIST.CUSTOMER_NAME}
                                                </label>
                                                <input
                                                    maxLength={MAX_LENGTH.NAME}
                                                    type='text'
                                                    name='customerName'
                                                    onChange={(e) => {
                                                        !touched.customerName &&
                                                            setTouched({
                                                                ...touched,
                                                                customerName: true,
                                                            });
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.customerName}
                                                    className={`new-customer-input input-area-set form-control ${isFormFocused && errors.customerName && touched.customerName && errors.customerName ? 'border border-danger-subtle border-1' : ''}`}
                                                    placeholder={AUTH.ENTER_CUSTOMER_NAME}
                                                />
                                                {isFormFocused &&
                                                    touched.customerName &&
                                                    errors.customerName && (
                                                        <p className='error-message'>
                                                            {errors.customerName}
                                                        </p>
                                                    )}
                                            </div>
                                            <div className='input-set-area mb-4'>
                                                <label htmlFor='phoneNumber'>
                                                    {AUTH.PHONE_NUMBER}
                                                </label>
                                                <input
                                                    maxLength={MAX_LENGTH.NAME}
                                                    type='text'
                                                    name='phone'
                                                    onChange={(e) => {
                                                        !touched.phone &&
                                                            setTouched({
                                                                ...touched,
                                                                phone: true,
                                                            });
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.phone}
                                                    className={`new-customer-input input-area-set form-control ${isFormFocused && errors.phone && touched.phone && errors.phone ? 'border border-danger-subtle border-1' : ''}`}
                                                    placeholder='phone Number'
                                                />
                                                {isFormFocused && touched.phone && errors.phone && (
                                                    <p className='error-message'>{errors.phone}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='input-set-area mb-4'>
                                                <label htmlFor='emailAddress'>
                                                    {AUTH.EMAIL_ADDRESS}
                                                </label>
                                                <input
                                                    maxLength={MAX_LENGTH.EMAIL}
                                                    type='email'
                                                    name='email'
                                                    onChange={(e) => {
                                                        !touched.email &&
                                                            setTouched({ ...touched, email: true });
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    className={`new-customer-input input-area-set form-control ${isFormFocused && errors.email && touched.email && errors.email ? 'border border-danger-subtle border-1' : ''}`}
                                                    placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                                />
                                                {isFormFocused && touched.email && errors.email && (
                                                    <p className='error-message'>{errors.email}</p>
                                                )}
                                            </div>
                                            <div className='input-set-area mb-4'>
                                                <label htmlFor=''>{DOCUMENTS.CSM}</label>
                                                <div
                                                    className={`wrapper position-relative ${openCsmDropdown ? 'active' : ''}`}
                                                    id='wrapper'
                                                    ref={dropdownRef}
                                                >
                                                    <div
                                                        className='select-btn'
                                                        id='select-btn'
                                                        onClick={() =>
                                                            setOpenCsmDropdown(!openCsmDropdown)
                                                        }
                                                    >
                                                        <span
                                                            className={
                                                                !selectCsm.name
                                                                    ? 'new-customer-input'
                                                                    : 'select-option-csm'
                                                            }
                                                        >
                                                            {selectCsm.name
                                                                ? selectCsm.name
                                                                : CSM.SELECT_CSM}
                                                        </span>
                                                        <div className=''>
                                                            {selectCsm.name ? (
                                                                <button
                                                                    type='button'
                                                                    className='btn-close w-25 me-1'
                                                                    data-bs-dismiss='modal'
                                                                    aria-label='Close'
                                                                    onClick={handleClear}
                                                                />
                                                            ) : (
                                                                <DownArrow />
                                                            )}
                                                        </div>
                                                    </div>
                                                    {openCsmDropdown && (
                                                        <div className='content position-absolute w-100'>
                                                            <div className='search position-relative'>
                                                                <SearchIcon className={'search-icon-svg'} width='16' height='16' />
                                                                <input
                                                                    type='search'
                                                                    name=''
                                                                    value={searchCsm}
                                                                    onChange={handleSearchCsm}
                                                                    placeholder='Search'
                                                                    className='input-dropdown-set'
                                                                />
                                                            </div>
                                                            <ul className='options mb-0'>
                                                                {csm.map((csmData, index) => (
                                                                    <li
                                                                        key={index}
                                                                        onClick={()=>handleClose({id:csmData.id, name:csmData.full_name})}
                                                                    >
                                                                        <span>
                                                                            {csmData.full_name}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className='d-flex align-items-center justify-content-end modal-btn'>
                                <button
                                    type='button'
                                    className='ssc-secondary-white-btn '
                                    onClick={() => {
                                        setShowAddModal(false);
                                    }}
                                >
                                    {MODAL.CANCEL_BUTTON}
                                </button>
                                <button
                                    type='submit'
                                    onClick={handleSubmit}
                                    className='ssc-primary-green-btn py-0 w-auto'
                                    disabled={
                                        isSubmitting ||
                                        !dirty ||
                                        errors.name ||
                                        errors.email ||
                                        errors.phone
                                    }
                                >
                                    {BUTTONS.SAVE_BUTTON}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

AddNewCustomer.propTypes= {
    showAddModal : PropTypes.bool,
    setShowAddModal: PropTypes.func
}
