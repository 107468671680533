import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDebounce } from '../../../hooks';
import { ButtonComponent } from '../Buttons/ButtonComponent';
import { LOCAL_STORAGE, ROUTES, NOTIFICATION, ROLES, INFO } from '../../../constants';
import { setLoaderVisibility, setNotificationUpdate, setIsUserAdmin, setNavigationState } from '../../../redux';
import { SSCLogo, BellIcon, User, ProfileIcon, Arrow, EmptyNotificationPanel, Email } from '../../../assets/svg';
import { getLocalStorageItem, popup, getAllNotificationsByAccountFilters, putMarkAllAsReadNotificationByAccount, putMarkAsReadNotification, getProfileImage, sendReport, createSubscriptionExpiryObject, getSubscriptionStatus, getAccountDetailsFromAccountArrayByAccountId, convertTimestampToDate } from '../../../utils';
import { TooltipComponent } from './Tooltip';
import { CustomDropdown } from './CustomDropdown';
import { ConfirmationBox } from './ConfirmationBox';

export const Header = ({
    isAdmin,
    isErrorPage = false,
    accounts = [],
    selectedAccount = '',
    setSelectedAccount,
    showCustomerDropdown = false,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const profilePanelRef = useRef();
    const notificationPanelRef = useRef();

    const userName = useSelector(state => state.user.fullName);
    const profileImg = useSelector(state => state.user.imagePath);
    const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_NAME);
    const userId = getLocalStorageItem(LOCAL_STORAGE.USER_ID);
    const { selectedAccountName } = useSelector((state) => state.accountArray);

    const isNotificationSyncRequired = useSelector((state) => state.notificationUpdate);

    const [openProfilePanel, setOpenProfilePanel] = useState(false);
    const [isAccountDropDownOpen, setIsAccountDropDownOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState(0);
    const [openNotificationsPanel, setOpenNotificationsPanel] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const roleCode = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);
    const customerID = getLocalStorageItem(LOCAL_STORAGE.CUSTOMER_ID);
    const [isDisabled, setIsDisabled] = useState(false);
    const tpaLength = getLocalStorageItem(LOCAL_STORAGE.PLATFORMS_LENGTH);
    const [subscriptionExpiryNotification, setSubscriptionExpiryNotification] = useState(null);
    const [selectedAccountDetail, setSelectedAccountDetail] = useState(null);
    const accountStates = useSelector(state => state.isSubscriptionExpiryRead.accountStates);
    const subscriptionExpiryObj = createSubscriptionExpiryObject(accounts);

    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    
    const handleNotificationClick = (notification) => {
        setOpenNotificationsPanel(false);
        (!notification.is_read && notification.type !== 'regulatory_issue' && notification.type !== 'subscription_expiry') && handleMarkAsRead(notification.id);
        dispatch( setNavigationState({ id: notification.id, type: notification.type }));
        navigate(isAdmin ? ROUTES.ADMIN.NOTIFICATION : ROUTES.CUSTOMER.NOTIFICATION, {
            state: {
                notification: {
                    id: notification.id,
                    type: notification.type
                }
            }
        });
    }

    const handleMarkAsRead = (notificationId) => {
        putMarkAsReadNotification({ notificationId: notificationId, accountId: selectedAccount || '' })
            .then((res) => {
                if (res.status === 'success') {
                    dispatch(setNotificationUpdate(prev => !prev));
                    debouncedApiCall();
                }
            }).catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            })
    }

    const handleMarkAllAsReadClick = () => {
        if (newNotifications !== 0) {
            dispatch(setLoaderVisibility(true));
            putMarkAllAsReadNotificationByAccount({ accountId: selectedAccount || '' })
                .then((res) => {
                    if (res.status === 'success') {
                        dispatch(setNotificationUpdate(prev => !prev));
                        debouncedApiCall();
                    }
                }).catch((error) => {
                    dispatch(setLoaderVisibility(false));
                    popup('error', error.message);
                })
        }
    }

    const handleClickOutside = (event) => {
        if (profilePanelRef.current && !profilePanelRef.current.contains(event.target)) {
            setOpenProfilePanel(false);
        }

        if (notificationPanelRef.current && !notificationPanelRef.current.contains(event.target)) {
            setOpenNotificationsPanel(false);
        }
    };

    const handleLogout = () => {
        dispatch(setLoaderVisibility(true));
        setTimeout(() => {
            localStorage.clear();
            dispatch({ type: 'reset/store' });
            dispatch(setLoaderVisibility(false));
            window.location.href = ROUTES.HOME;
        }, 1000);
    };

    const debouncedApiCall = useDebounce(() => {
        const reqData = isAdmin ? {
            include_all_notifications: false,
        } : {
            include_all_notifications: true,
            accountId: selectedAccount || ''
        }

        getAllNotificationsByAccountFilters(reqData)
            .then((res) => {
                if(res?.data && res.data.length) {
                    setNotifications(res.data);
                    setNewNotifications(res.new_notifications);
                } else {
                    setNotifications([]);
                    setNewNotifications(0);
                }
                dispatch(setLoaderVisibility(false));
            })
            .catch((error) => {
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 1000);

    const handleSendReport = async () => {
        setOpenConfirmationBox(true);
    };

    useEffect(() => {
        async function handleSendReport() {
            if(confirm) {
                setIsDisabled(true);
                sendReport({ id: customerID });
                popup('success', INFO.SEND_REPORT_USER);
                setIsDisabled(false);
            }
        }
        handleSendReport();
    }, [confirm]);

    // initial state

    useEffect(() => {
        debouncedApiCall();
    }, [selectedAccount, isNotificationSyncRequired, location.pathname]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(setIsUserAdmin());
    }, []);

    useEffect(() => {
        const _selectedAccountDetail = getAccountDetailsFromAccountArrayByAccountId(accounts, selectedAccount);
        setSelectedAccountDetail(_selectedAccountDetail);

        if (_selectedAccountDetail?.is_subscription_expiry_read || accountStates?.[selectedAccount]) {
            const expiryDetails = subscriptionExpiryObj[selectedAccount];
            const _subscriptionExpiryNotification = getSubscriptionStatus(expiryDetails.subscription_expiry);
            _subscriptionExpiryNotification.type = 'subscription_expiry';
            _subscriptionExpiryNotification.id = 'subscription_expiry';

            setSubscriptionExpiryNotification(_subscriptionExpiryNotification);
        }
    }, [selectedAccount, accounts, accountStates?.[selectedAccount], isNotificationSyncRequired, location.pathname]);

    useEffect(() => {
        if (profileImg) {
            getProfileImage({ profileImg }).then((url) => setPreviewUrl(url)).catch(() => setPreviewUrl(null));
        } else setPreviewUrl(null);
    }, [profileImg]);

    return (
        <header
            className={`ssc-header ${isAdmin ? '' : 'header-position-relative'} d-flex align-items-center justify-content-between bg-white`}
        >
            {/* <!-- right side --> */}
            <div className='ssc-logo '>
                <Link to='/' className='logo-area'>
                    <SSCLogo width={173} height={32} className={'img-fluid logo'} />
                </Link>
            </div>

            {/* <!-- left-side --> */}
            {!isErrorPage && (
                <div className='ssc-header-right d-flex align-items-center'>
                    {showCustomerDropdown && accounts.length > 0 && (
                        <CustomDropdown
                            options={accounts}
                            handleOptionSelect={(option)=>{ setSelectedAccount(option.id); setIsAccountDropDownOpen(false); }}
                            isDropdownOpen={isAccountDropDownOpen}
                            setIsDropdownOpen={setIsAccountDropDownOpen}
                            selectedOption={selectedAccountName}
                        />
                    )}
                    {/* <!-- Email icon --> */}
                    {tpaLength > 0 && [ROLES.PRIMARY_USER].includes(roleCode) && (
                        <Button
                            className='position-relative ssc-bell-btn border-0 p-0'
                            type={'button'}
                            aria-controls='MenuSidebarMobileView4'
                            onClick={handleSendReport}
                            disabled={isDisabled}
                        >
                            <TooltipComponent tooltipText='Send Report' placement='bottom'>
                                <Email />
                            </TooltipComponent>
                        </Button>
                    )}
                    {/* <!-- notification-bell icon --> */}
                    <div ref={notificationPanelRef}>
                        <ButtonComponent
                            bootstrapButton={Button}
                            className='position-relative ssc-bell-btn border-0 p-0'
                            type={'button'}
                            clickHandler={setOpenNotificationsPanel}
                            ariaControls='MenuSidebarMobileView4'
                            ariaExpanded={openNotificationsPanel}
                        >
                            <BellIcon />
                            {newNotifications !== 0 && (
                                <span className='notification-num position-absolute d-flex align-items-center justify-content-center'>
                                    {' '}
                                    {newNotifications > 99 ? 99 : newNotifications}
                                </span>
                            )}
                        </ButtonComponent>
                        {/*<!-- notification-dropdown module -->*/}
                        <Collapse in={openNotificationsPanel}>
                            <div className='navbar-collapse bg-white' id='MenuSidebarMobileView4'>
                                <div className='p-3 notification-title d-flex align-items-center justify-content-between'>
                                    <div>
                                        <div className='d-flex align-items-center mb-3'>
                                            <h5 className='mb-0'>{NOTIFICATION.HEADING}</h5>
                                            {newNotifications !== 0 && (
                                                <span className='d-flex align-items-center justify-content-center'>
                                                    {newNotifications} NEW
                                                </span>
                                            )}
                                        </div>
                                        <button
                                            className={
                                                'mb-0 link-text notification-mark-all-as-read button' +
                                                (newNotifications ? '' : ' disabled')
                                            }
                                            onClick={handleMarkAllAsReadClick}
                                        >
                                            {NOTIFICATION.MARK_ALL_READ}
                                        </button>
                                    </div>
                                </div>
                                <div className='notification-list-custom'>
                                    { (selectedAccountDetail?.is_subscription_expiry_read || accountStates?.[selectedAccount]) &&
                                        <div key={subscriptionExpiryNotification?.id} className={"p-3 w-100 notification-text bar"} onClick={() => handleNotificationClick(subscriptionExpiryNotification)}>
                                            <div className="d-flex align-items-start justify-content-between">
                                                <div className="w-100 pe-3">
                                                    <h5 className="title">
                                                        {subscriptionExpiryNotification?.title}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {notifications.length > 0 ? (
                                        notifications.map((notification) => (
                                            <div key={notification.id} className={"p-3 w-100 notification-text bar" + (!notification.is_read && notification.type !== 'regulatory_issue' && notification.type !== 'subscription_expiry' ? " new-notification" : "")} onClick={() => handleNotificationClick(notification)}>
                                                <div className="d-flex align-items-start justify-content-between">
                                                    <div className="w-100 pe-3">
                                                        <h5 className="title">
                                                            {notification.title}
                                                        </h5>
                                                        <p className='small fst-italic margin-top-7'>
                                                            {`${convertTimestampToDate(notification.created_at).formattedDate} 
                                                             ${convertTimestampToDate(notification.created_at).formattedTime}`}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : ( (!selectedAccountDetail?.is_subscription_expiry_read && !accountStates?.[selectedAccount]) &&
                                            <div className='d-flex align-items-center justify-content-center h-100 my-4 flex-column'>
                                                <EmptyNotificationPanel width={300} height={250}/>
                                                <span className='pt-3'>{NOTIFICATION.NO_NOTIFICATIONS}</span>
                                            </div>
                                        )
                                    }
                                </div>

                                <div>
                                    { (selectedAccountDetail?.is_subscription_expiry_read || accountStates?.[selectedAccount] || notifications.length > 0 ) && (
                                        <Link
                                            to={
                                                isAdmin
                                                    ? ROUTES.ADMIN.NOTIFICATION
                                                    : ROUTES.CUSTOMER.NOTIFICATION
                                            }
                                            state={selectedAccount}
                                            className='all-notification-page-btn p-3 d-flex align-items-center'
                                            onClick={() => {
                                                dispatch(setNavigationState(null));
                                                setOpenNotificationsPanel(false)
                                            }}
                                        >
                                            {NOTIFICATION.VIEW_ALL}
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </Collapse>
                    </div>

                    {/* <!-- profile-dropdown --> */}
                    <div className='ssc-profile-dropdown' ref={profilePanelRef}>
                        <ButtonComponent
                            bootstrapButton={Button}
                            className='ssc-header-dropdown d-flex align-items-center p-0'
                            clickHandler={setOpenProfilePanel}
                            ariaControls='MenuSidebarMobileView'
                            ariaExpanded={openProfilePanel}
                        >
                            <div className='img-area d-flex align-items-center justify-content-center'>
                                {previewUrl && previewUrl !== null ? (
                                    <img
                                        src={previewUrl}
                                        alt='Preview'
                                        className='header-image'
                                        width={35}
                                        height={35}
                                    />
                                ) : (
                                    <User width={24} height={24} />
                                )}
                            </div>
                            <div className='dropdown-arrow d-flex align-items-center'>
                                <Arrow width={16} height={16} />
                            </div>
                        </ButtonComponent>
                        <Collapse
                            in={openProfilePanel}
                            className='collapse navbar-collapse bg-white'
                            id='MenuSidebarMobileView'
                        >
                            <div className='p-4 flex-column d-flex align-items-center justify-content-center'>
                                <div className='profile-img d-flex align-items-center justify-content-center'>
                                    {previewUrl ? (
                                        <img
                                            src={previewUrl}
                                            alt='Preview'
                                            className='header-image'
                                            width={56}
                                            height={56}
                                        />
                                    ) : (
                                        <ProfileIcon
                                            width={56}
                                            height={56}
                                            className={'img-user-profile'}
                                        />
                                    )}
                                </div>
                                <div className='text-center user-name'>
                                    <span>{userName}</span>
                                    <br />
                                    <span>({userRole})</span>
                                </div>
                                <div className='flex-column d-flex align-items-start justify-content-start w-100'>
                                    <Link
                                        onClick={() => {
                                            setOpenProfilePanel(false);
                                        }}
                                        to={
                                            isAdmin
                                                ? ROUTES.ADMIN.EDIT_PROFILE
                                                : ROUTES.CUSTOMER.EDIT_PROFILE
                                        }
                                        state={{ userId: userId, header: true, userRole }}
                                        className='link-div py-1 w-100'
                                    >
                                        Profile
                                    </Link>
                                    <hr className='line-separtor w-100 my-2' />
                                    <span
                                    
                                        className='link-div py-1 w-100 cursor-pointer'
                                        onClick={() => {
                                            setOpenProfilePanel(false);
                                            handleLogout();
                                        }}
                                    >
                                        Log Out
                                    </span>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            )}
            <ConfirmationBox
                setConfirm={setConfirm}
                openConfirmationBox={openConfirmationBox}
                setOpenConfirmationBox={setOpenConfirmationBox}
                customMessage={INFO.CONFIRM_SEND_REPORT}
            />
        </header>
    );
};

Header.propTypes = {
    isAdmin: PropTypes.bool,
    isErrorPage: PropTypes.bool.isRequired,
    accounts: PropTypes.array,
    selectedAccount: PropTypes.string,
    setSelectedAccount: PropTypes.func,
    showCustomerDropdown: PropTypes.bool.isRequired,
};

Header.defaultProps = {
    isErrorPage: false,
    accounts: [],
    showCustomerDropdown: false,
};
