import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility, setUserData } from '../../../redux';
import { updatePrimaryUser, formValidator, getUserById, popup, validateFiles, signedURL, getLocalStorageItem, compressImage, getProfileImage, getCustomerByID, getAllCSM, updateAccount } from '../../../utils';
import { AUTH, REGEX, VALIDATION, MAX_LENGTH, LOCAL_STORAGE, PROFILE_IMAGE } from '../../../constants';
import { UserCircle, ImgEdit, Arrow, SearchIcon } from '../../../assets/svg';
import { DeleteConfirmationModal } from '../Modal/DeleteConfirmationModal';
import { AdminPageHeader } from '../../Admin';

export const EditProfile = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const editAccess = (getLocalStorageItem(LOCAL_STORAGE.ROLE_NAME) === 'Admin' || getLocalStorageItem(LOCAL_STORAGE.USER_ID) === location.state?.userId);
    const editEmail = ['Admin', 'CSM', 'Super Admin'].includes(getLocalStorageItem(LOCAL_STORAGE.ROLE_NAME));

    const [userDetails, setUserDetails] = useState({});
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [imageChanged, setImageChanged] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [isFormFocused, setIsFormFocused] = useState(false);
    const [isCsmDropdownOpen, setIsCsmDropdownOpen] = useState(null);
    const [selectedCSM, setSelectedCSM] = useState([])
    const [csmList, setCsmList] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [csmChanged, setCsmChanged] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const debouncedApiCall = useDebounce(async () => {
        try {

            if (location.state?.customerProfile) {
                const res = await getCustomerByID({
                    customerID: location.state?.customerId,
                    fetchTPA: false,
                    fetchHiringClient: false,
                    fetchCSM: false,
                });
                setUserDetails(res.data[0]);
                setSelectedCSM({ name: res.data[0].customer.csm_name });
                setCsmChanged(false);
            } else {
                const res = await getUserById({
                    id: location.state?.userId
                });
                if (res?.data) setUserDetails(res.data);
                if (res?.data?.image) {
                    getProfileImage({ profileImg: res.data.image }).then((url) => setPreviewUrl(url)).catch(() => setPreviewUrl(null));
                }
                location.state.header && dispatch(setUserData({
                    firstName: res?.data?.first_name,
                    lastName: res?.data?.last_name,
                    imagePath: res?.data?.image || null
                }));

                setImageChanged(false);
            }
            setRefresh(false);
            dispatch(setLoaderVisibility(false));
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    }, 500);

    // validation schema for validating for fields
    const validationSchema = {
        firstName: {
            regex: REGEX.NAME,
            message: VALIDATION.INVALID_FIRST_NAME,
            requiredMessage: VALIDATION.FIRST_NAME_REQUIRED,
        },
        lastName: {
            regex: REGEX.NAME,
            message: VALIDATION.INVALID_LAST_NAME,
            requiredMessage: VALIDATION.LAST_NAME_REQUIRED,
        },
        email: {
            regex: REGEX.EMAIL,
            message: VALIDATION.INVALID_EMAIL,
            isNotRequired: true
        },
        phone: {
            regex: REGEX.PHONE,
            message: VALIDATION.INVALID_PHONE,
            isNotRequired: true
        },
        accountName: {
            regex: REGEX.ACCOUNT_NAME,
            message: VALIDATION.ACCOUNT_NAME,
            requiredMessage: VALIDATION.ACCOUNT_NAME_REQUIRED,
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.user-input-section') === null)
                setTimeout(() => {
                    setIsFormFocused(false);
                }, 100);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        dispatch(setLoaderVisibility(true));
        debouncedApiCall();
    }, [refresh, location.state?.userId, location.state?.header]);

    useEffect(() => {
        const fetchCSMData = async () => {
            try {
                const csmData = await getAllCSM({
                    search: searchQuery,
                });
                setCsmList(csmData?.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchCSMData();
    }, [searchQuery, isCsmDropdownOpen]);

    const onFileChange = (event) => {
        try {
            const files = event.target.files;
            validateFiles(files, selectedFiles);
            const selectedFilesArray = Array.from(files);

            if (selectedFilesArray[0]) {
                const fileType = selectedFilesArray[0].type;
                if (fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'image/jpeg') {

                    setSelectedFiles([selectedFilesArray]);
                    const selectedFileNames = selectedFilesArray[0].name;
                    setFileNames([selectedFileNames]);
                    setImageChanged(true);

                    // Preview image
                    const reader = new FileReader();
                    reader.onload = () => {
                        setPreviewUrl(reader.result);
                    };
                    reader.readAsDataURL(files[0]);
                }
                else {
                    popup('error', PROFILE_IMAGE.NOT_ALLOWED);
                }
            }
        } catch (error) {
            popup('error', error.message);
        }
    };

    // to handle upload file logic
    const openFileExplorer = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png, image/jpg, image/jpeg';
        input.multiple = false; // Allow multiple file selection
        input.onchange = onFileChange;
        input.click();
    };

    const handleFileUpload = async ({ data }) => {
        try {
            dispatch(setLoaderVisibility(true));
            if (location.state?.customerProfile) {
                delete data.firstName;
                delete data.lastName;
                data.csm_id = selectedCSM.id
                await updateAccount({ id: userDetails?.customer?.account_id, body: { csm_id: data?.csm_id, email: data?.email ?? null, name: data?.name, phone: data?.phone } })
                setRefresh(true);
                popup('success', PROFILE_IMAGE.UPDATED);
            } else {
                if (selectedFiles.length) {
                    const compressedImageBlob = await compressImage(selectedFiles[0][0]);
                    const response = await signedURL({ data: { user_id: userDetails?.id, objects: fileNames } });
                    const urlData = response?.data;
                    const { preSignedUrl, filePath } = urlData[0];
                    const uploadResponse = await fetch(preSignedUrl, {
                        method: 'PUT',
                        body: compressedImageBlob,
                        headers: {
                            'Content-Type': 'application/octet-stream',
                        },
                    });
                    if (!uploadResponse.ok) {
                        throw new Error('Failed to upload file');
                    } else { data.image = filePath }
                } else { data.image = null }
                await updatePrimaryUser({ id: userDetails?.id, data });
                setRefresh(true);
                popup('success', PROFILE_IMAGE.UPDATED);
            }
        } catch (error) {
            dispatch(setLoaderVisibility(false));
            popup('error', error.message);
        }
    };

    const handleCsmDropdown = () => {
        setIsCsmDropdownOpen((prev) => !prev);
    };

    const handleSelectCsm = (csm) => {
        setSelectedCSM({ ...csm, name: `${csm.first_name} ${csm.last_name}` });
        setCsmChanged(true);
        setIsCsmDropdownOpen(false);
    };

    const handleSearchChange = (event) => {
        const search = event.target.value.trim();
        setSearchQuery(search);
    };

    const handleRemoveImg = () => {
        setSelectedFiles([]);
        setPreviewUrl(null);
        setImageChanged(true);
        setFileNames([]);
        setDeleteModal(false);
    }

    const checkFormFocus = () => setIsFormFocused(true);
    return (
        <div>
            <AdminPageHeader
                heading={'Profile'}
                backbutton
                showSearchBar={false}
            />
            <div className="profile-box col-xl-10 col-lg-11">
                <div className="row justify-content-center">
                    {
                        !location.state?.customerProfile && <div className="col-3">
                            <div className="profile-img d-flex align-items-center justify-content-center flex-column">
                                <div className="position-relative mb-4">
                                    {previewUrl && <button className="close rounded border-0 remove-img" onClick={() => setDeleteModal(true)}>
                                        <span>
                                            &times;
                                        </span>
                                    </button>}
                                    {previewUrl ? <img src={previewUrl} alt="Preview" width={80} height={80} className='mb-0' /> : <UserCircle width={80} height={80} />}
                                    {((editAccess && !location.state?.adminData) || location.state?.header) && <button className="bg-white border-0 img-edit" onClick={openFileExplorer}>
                                        <ImgEdit width={211} height={211} />
                                    </button>}
                                </div>
                                <h4 className="mb-3 text-center">
                                    {userDetails.full_name}
                                </h4>
                                <p className="mb-0 mt-0 text-center">
                                    {userDetails.role_name}
                                </p>
                            </div>
                        </div>
                    }
                    <div className="col-9">
                        <div className="ps-xl-5 ps-lg-3">
                            <div className="tabs-set">
                                <div className="tabs-row d-flex align-items-center justify-content-start">
                                    <span className="d-flex align-items-center justify-content-center link-profile active">
                                        Personal Info
                                    </span>
                                </div>
                                {userDetails && Object.keys(userDetails).length > 0 && (<Formik
                                    enableReinitialize
                                    initialValues={{
                                        firstName: userDetails?.first_name,
                                        lastName: userDetails?.last_name,
                                        email: userDetails?.email || userDetails?.customer?.email,
                                        phone: userDetails?.phone?.replace(/-/g, '') || userDetails?.customer?.phone?.replace(/-/g, ''),
                                        accountName: userDetails?.customer?.name,
                                    }} validate={(values) => {
                                        const errors = {};
                                        const { firstName, lastName, accountName, ...rest } = values;
                                        if (location.state?.customerProfile) {
                                            formValidator({ values: { accountName, ...rest }, errors, validationSchema });
                                        } else {
                                            formValidator({ values: { firstName, lastName, ...rest }, errors, validationSchema });
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        handleFileUpload({
                                            data: {
                                                first_name: values?.firstName,
                                                last_name: values?.lastName,
                                                email: values.email || userDetails?.email,
                                                phone: values?.phone,
                                                name: values?.accountName,
                                            }
                                        })
                                            .then(() => {
                                                setSubmitting(false);
                                            })
                                            .catch((error) => {
                                                setSubmitting(false);
                                                popup('error', error.message);
                                            });
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        dirty,
                                        isSubmitting,
                                        setTouched,
                                    }) => (
                                        <div className="form-area mt-4">
                                            <form
                                                onSubmit={handleSubmit}
                                            >
                                                <div className='user-input-section w-100'
                                                    onFocus={() => { checkFormFocus() }}
                                                >
                                                    <>
                                                        {
                                                            location.state?.customerProfile ? (
                                                                <div>
                                                                    <div className='imb-3 input-set w-100'>
                                                                        <label htmlFor=''>{AUTH.ACCOUNT_NAME}</label>
                                                                        <div className='position-relative'>
                                                                            <input
                                                                                disabled={!editAccess}
                                                                                maxLength={MAX_LENGTH.NAME}
                                                                                type='text'
                                                                                name='accountName'
                                                                                onChange={(e) => {
                                                                                    !touched.accountName &&
                                                                                        setTouched({ ...touched, accountName: true });
                                                                                    handleChange(e);
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values.accountName}
                                                                                className={`form-control ${isFormFocused && errors.accountName && touched.accountName && errors.accountName ? 'border border-danger-subtle border-1' : ''}`}
                                                                                placeholder={AUTH.ENTER_ACCOUNT_NAME}
                                                                            />
                                                                            {isFormFocused && touched.accountName && errors.accountName && (
                                                                                <p className='error-message'>{errors.accountName}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className='imb-3 input-set w-100'>
                                                                        <label htmlFor=''>{AUTH.CSM_NAME}</label>
                                                                        <div className={`wrapper position-relative${isCsmDropdownOpen && editAccess ? ' active' : ''}`} id="wrapper">
                                                                            <div onClick={handleCsmDropdown} className={`select-btn ${!editAccess ? 'field-disabled' : ''}`} id="select-btn">
                                                                                {selectedCSM ? <span style={{ color: 'var(--col-primary)' }}> {selectedCSM.name} </span> : <span> Select CSM </span>}
                                                                                <div><Arrow /></div>
                                                                            </div>
                                                                            <div className="content position-absolute w-100">
                                                                                <div className="search position-relative">
                                                                                    <div className='search-icon-svg'>
                                                                                        <SearchIcon width={13} height={13} />
                                                                                    </div>
                                                                                    <input onChange={handleSearchChange} type="search" name="" placeholder="Search" value={searchQuery} id="" className="input-dropdown-set" />
                                                                                </div>
                                                                                <ul className="options mb-0">
                                                                                    {csmList?.map((item, index) => (
                                                                                        <li key={index} onClick={() => handleSelectCsm(item)}>{`${item.first_name} ${item.last_name}`}</li>
                                                                                    ))}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className='mb-3 input-set w-100'>
                                                                        <label htmlFor=''>{AUTH.FIRST_NAME}</label>
                                                                        <div className='position-relative'>
                                                                            <input
                                                                                disabled={(!editAccess || location.state?.adminData) && !location.state?.header}
                                                                                autoFocus={true}
                                                                                maxLength={MAX_LENGTH.NAME}
                                                                                type='text'
                                                                                name='firstName'
                                                                                onChange={(e) => {
                                                                                    !touched.firstName &&
                                                                                        setTouched({ ...touched, firstName: true });
                                                                                    handleChange(e);
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values.firstName}
                                                                                className={`form-control ${isFormFocused && errors.firstName && touched.firstName && errors.firstName ? 'border border-danger-subtle border-1' : ''}`}
                                                                                placeholder={AUTH.ENTER_FIRST_NAME}
                                                                            />
                                                                            {isFormFocused && touched.firstName && errors.firstName && (
                                                                                <p className='error-message'>{errors.firstName}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className='mb-3 input-set w-100'>
                                                                        <label htmlFor=''>{AUTH.LAST_NAME}</label>
                                                                        <div className='position-relative'>
                                                                            <input
                                                                                disabled={((!editAccess || location.state?.adminData) && !location.state?.header)}
                                                                                maxLength={MAX_LENGTH.NAME}
                                                                                type='text'
                                                                                name='lastName'
                                                                                onChange={(e) => {
                                                                                    !touched.lastName &&
                                                                                        setTouched({ ...touched, lastName: true });
                                                                                    handleChange(e);
                                                                                }}
                                                                                onBlur={handleBlur}
                                                                                value={values.lastName}
                                                                                className={`form-control ${isFormFocused && errors.lastName && touched.lastName && errors.lastName ? 'border border-danger-subtle border-1' : ''}`}
                                                                                placeholder={AUTH.ENTER_LAST_NAME}
                                                                            />
                                                                            {isFormFocused && touched.lastName && errors.lastName && (
                                                                                <p className='error-message'>{errors.lastName}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </>)
                                                        }
                                                    </>

                                                    <div className='mb-3 input-set w-100'>
                                                        <label htmlFor=''>{AUTH.EMAIL_ADDRESS}</label>
                                                        <div className='position-relative'>
                                                            <input
                                                                disabled={editEmail && (!location.state?.customerProfile || !editAccess)}
                                                                maxLength={MAX_LENGTH.EMAIL}
                                                                type='email'
                                                                name='email'
                                                                onChange={(e) => {
                                                                    !touched.email &&
                                                                        setTouched({ ...touched, email: true });
                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.email}
                                                                className={`form-control ${isFormFocused && errors.email && touched.email && errors.email ? 'border border-danger-subtle border-1' : ''}`}
                                                                placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                                            />
                                                            {isFormFocused && touched.email && errors.email && (
                                                                <p className='error-message'>{errors.email}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className='mb-3 input-set w-100'>
                                                        <label htmlFor=''>{AUTH.PHONE_NUMBER}</label>
                                                        <div className='position-relative'>
                                                            <input
                                                                disabled={((!editAccess || location.state?.adminData) && !location.state?.header && (!location.state?.customerProfile || !editAccess))}
                                                                maxLength={MAX_LENGTH.PHONE}
                                                                type='phone'
                                                                name='phone'
                                                                onChange={(e) => {
                                                                    !touched.phone &&
                                                                        setTouched({ ...touched, phone: true });
                                                                    handleChange(e);
                                                                }}
                                                                onBlur={handleBlur}
                                                                value={values.phone}
                                                                className={`form-control ${isFormFocused && errors.phone && touched.phone && errors.phone ? 'border border-danger-subtle border-1' : ''}`}
                                                                placeholder={AUTH.ENTER_PHONE}
                                                            />
                                                            {isFormFocused && touched.phone && errors.phone && (
                                                                <p className='error-message'>{errors.phone}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-end mb-1">
                                                    <button
                                                        type='submit'
                                                        className='form-button d-flex align-items-center justify-content-center border-0 profile-button'
                                                        disabled={
                                                            isSubmitting ||
                                                            (!csmChanged && !imageChanged && !dirty) ||
                                                            ((errors.firstName ||
                                                                errors.lastName) || errors.accountName) ||
                                                            errors.email ||
                                                            errors.phone
                                                        }
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    )}
                                </Formik>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deleteModal && (
                <DeleteConfirmationModal
                    isDeleteModal={deleteModal}
                    setIsDeleteModal={setDeleteModal}
                    handleDeleteClick={handleRemoveImg}
                    deleteButtonName={PROFILE_IMAGE.DELETE_PROFILE_IMAGE}
                />
            )}
        </div>
    );
}
