import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setLoaderVisibility } from '../../../redux';
import { DocumentCategoryAddEdit, DeleteConfirmationModal } from '../../shared';
import { Edit, Delete } from '../../../assets/svg';
import { deleteDocumentCategory, popup } from '../../../utils';
import { DOCUMENT_CATEGORY, SUCCESS } from '../../../constants';

export const DocumentCategoryOperation = ({ data, debouncedApiCall }) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(0);
    const handleDeleteMoodalClick = (data) => {
        setSelectedItemId(data?.id);
        setIsDeleteModal(true);
    };

    const handleDeleteClick = async (selectedItemId, setIsDeleteModal) => {
        dispatch(setLoaderVisibility(true));
        setTimeout(() => {
            deleteDocumentCategory(selectedItemId)
                .then(() => {
                    debouncedApiCall();
                    setIsDeleteModal(false);
                    popup(
                        SUCCESS.SUCCESS_TOAST,
                        DOCUMENT_CATEGORY.DELETED_MESSAGE
                    );
                })
                .catch((error) => {
                    popup('error', error.message);
                    dispatch(setLoaderVisibility(false));
                });
        }, 500);
    };

    return (
        <>
            <div className='d-flex align-items-center justify-content-start options-set'>
                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-edit'
                    onClick={() => setOpenEditModal(true)}
                >
                    <Edit />
                </button>

                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-del'
                    onClick={() => handleDeleteMoodalClick(data)}
                >
                    <Delete />
                </button>
            </div>
            {openEditModal && (
                <DocumentCategoryAddEdit
                    openEditModal={openEditModal}
                    setOpenEditModal={setOpenEditModal}
                    data={data}
                    isEdit={true}
                    debouncedApiCall={debouncedApiCall}
                />
            )}
            {isDeleteModal && (
                <DeleteConfirmationModal
                    isDeleteModal={isDeleteModal}
                    setIsDeleteModal={setIsDeleteModal}
                    selectedItemId={selectedItemId}
                    handleDeleteClick={() => handleDeleteClick(selectedItemId, setIsDeleteModal)}
                    deleteButtonName={DOCUMENT_CATEGORY.DELETE_BUTTON}
                />
            )}
        </>
    );
};

DocumentCategoryOperation.propTypes = {
    data: PropTypes.object.isRequired,
    debouncedApiCall: PropTypes.func
};