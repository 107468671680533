import React from 'react';
import PropTypes from 'prop-types';

const CardRightArrow = ({width=16, height=17}) => (
    <svg
        width={width}
        height={height}
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5.66675 3.83341L10.3334 8.50008L5.66675 13.1667'
            stroke='#738DA6'
            strokeWidth={1.5}
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

CardRightArrow.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}

export default CardRightArrow;
