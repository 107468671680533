import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { EDIT_CUSTOMER as EDIT } from '../../../constants';

import { ButtonComponent } from '../../shared';
import { BackArrow } from '../../../assets/svg';

export const Heading = ({ data }) => {
    const navigate = useNavigate();
    const handleBackButton = () => {
        navigate(-1);
    };
    return (
        <div className='title-btn mt-0 d-flex align-items-center border-0 bg-white'>
            <ButtonComponent
                className='d-flex align-items-center border-0 bg-white'
                type='button'
                clickHandler={handleBackButton}
            >
                <BackArrow />
            </ButtonComponent>
            <div className='d-flex w-100 justify-content-between align-items-center '>
                <span className='justify-content-start'>{EDIT.HEADING}</span>
                <span className='justify-content-end edit-customer-heading font-16px me-4'>{data?.account?.name}</span>
            </div>
        </div>
    );
};
Heading.propTypes = {
    data: PropTypes.object,
};
