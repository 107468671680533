import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setLoaderVisibility } from '../../../redux';
import { DeleteButton, ConfirmationBox } from '../../shared';
import {
    addNewAccountHcPlatform,
    deleteAccountHcPlatform,
    getAllHiringClient,
    popup,
} from '../../../utils';
import { EDIT_CUSTOMER as EDIT } from '../../../constants';
import { InputBoxWithSearch } from '../DocumentRequest/InputBoxWithSearch';
import { AddHiringClient } from './AddHiringClient';

export const HiringClientSection = ({
    handleClose,
    id,
    tpa,
    realData,
    hiringClientData,
    setHiringClientData,
    debouncedApiCall,
}) => {
    const dispatch = useDispatch();
    const [addedHC, setAddedHC] = useState('');
    const [newHC, setNewHC] = useState('');
    const [searchHC, setSearchHC] = useState('');
    const [dropdownData, setDropDownData] = useState([]);
    const [hcToDelete, setHcToDelete] = useState([]);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);

    const updateGradeInHiringClientData= (e, selectedHiringClient, setHiringClientData) => {
            const { value } = e.target;
            const indexInHCArray =hiringClientData.findIndex((pf) => pf.id === tpa.id,);
            if (indexInHCArray !== -1) {const indexInHC = hiringClientData[indexInHCArray].hiringClients.findIndex((oldHC) => oldHC.id === selectedHiringClient.id);
                if (indexInHC !== -1) {
                    setHiringClientData((pre) => {
                        const oldData = [...pre];
                        oldData[indexInHCArray].hiringClients[indexInHC].hiring_client_grade =value;
                        return [...oldData];
                    });
                }
            }
    }

    // if new HC Added
    useEffect(() => {
        if (addedHC) {
            setSearchHC('');
            if (
                tpa?.hiringClients ||
                hiringClientData.filter((pf) => pf.id === tpa.id)[0]?.hiringClients
            ) {
                if (
                    hiringClientData
                        .filter((pf) => pf.id === tpa.id)[0]
                        ?.hiringClients?.some((hc) => hc.id === addedHC.id)
                ) {
                    popup('error', EDIT.POPUP_MSG.HIRING_CLIENT_EXIST);
                } else {
                    setHiringClientData((pre) =>
                        pre.map((pf) =>
                            pf.id === tpa.id
                                ? { ...pf, hiringClients: [...pf.hiringClients, addedHC] }
                                : pf,
                        ),
                    );
                }
            } else {
                setHiringClientData((pre) =>
                    pre.map((pf) => (pf.id === tpa.id ? { ...pf, hiringClients: [addedHC] } : pf)),
                );
            }
        }
        setAddedHC('');
    }, [addedHC]);

    // To populate data in Dropdown if searched
    useEffect(() => {
        getAllHiringClient({ offset: 1, limit: 5, search: searchHC }).then((res) =>
            setDropDownData(res.data),
        );
    }, [searchHC]);

    // after Confirmation from checkbox
    useEffect(() => {
        if (confirm) {
            saveData();
        }
    }, [confirm]);

    // handle delete click
    const deleteHC = () => {
        if (hcToDelete.length) {
            const hcAfterDelete = hiringClientData
                .filter((pf) => pf.id === tpa.id)[0]
                .hiringClients.filter((hc) => !hcToDelete.includes(hc.id));
            setHiringClientData((pre) =>
                pre.map((pf) => (pf.id === tpa.id ? { ...pf, hiringClients: hcAfterDelete } : pf)),
            );
        }
        setHcToDelete([]);
    };

    // handle remove click
    const removeItem = (hc) => {
        const hcAfterRemove = hiringClientData
            .filter((pf) => pf.id === tpa.id)[0]
            .hiringClients.filter((HC) => HC.id !== hc.id);
        setHiringClientData((pre) =>
            pre.map((pf) => (pf.id === tpa.id ? { ...pf, hiringClients: hcAfterRemove } : pf)),
        );
    };

    // Select Hiring CLients
    const handleSelect = (id) => {
        if (!hcToDelete.includes(id)) {
            setHcToDelete((pre) => [...pre, id]);
        } else {
            setHcToDelete((pre) => pre.filter((hc) => hc !== id));
        }
    };

    // Handle save button to open confirmation box
    const handleSaveHC = () => {
        if (realData !== hiringClientData) {
            setOpenConfirmationBox(true);
        } else {
            popup('error', EDIT.POPUP_MSG.NOTHING_TO_SAVE);
        }
    };

    // API call to update Data
    const saveData = () => {
        setConfirm(false);
        setHcToDelete([]);
        dispatch(setLoaderVisibility(true));
        let finalData = [];
        if (hiringClientData.length) {
            hiringClientData
                .map((pf) =>
                    pf?.hiringClients?.length
                        ? pf.hiringClients.map((hc, index) => {
                              finalData.push({
                                  account_id: id,
                                  hiring_client_id: hc.id,
                                  platform_id: pf.id,
                                  hc_order: index + 1,
                                  hiring_client_grade: hc.hiring_client_grade || '',
                              });
                          })
                        : finalData.push({
                              account_id: id,
                              hiring_client_id: null,
                              platform_id: pf.id,
                              hc_order: 0,
                              hiring_client_grade: '',
                          }),
                )
                .flat();
        } else if (!hiringClientData.length) {
            deleteAccountHcPlatform({ body: { account_id: id } })
                .then(() => {
                    debouncedApiCall();
                    popup('success', EDIT.POPUP_MSG.PLATFORM_UPDATED);
                    handleClose();
                })
                .catch((err) => {
                    dispatch(setLoaderVisibility(false));
                    popup('error', err.message);
                });
        }
        if (finalData?.length) {
            addNewAccountHcPlatform({ body: finalData })
                .then(() => {
                    debouncedApiCall();
                    popup('success', EDIT.POPUP_MSG.PLATFORM_UPDATED);
                })
                .catch((err) => {
                    dispatch(setLoaderVisibility(false));
                    popup('error', err.message);
                });
        }
    };

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prev) => (prev === id ? null : id));
    };

    return (
        <div className='border user-create-form mt-2 ps-4 pe-4 mb-5'>
            <div
                className={`wrapper position-relative d-flex align-items-center gap-3 justify-content-between`}
                id='wrapper'
            >
                <div className='d-flex align-items-center w-75 gap-5'>
                    <InputBoxWithSearch
                        label={''}
                        placeholder={'Select Hiring Clients'}
                        dropdownData={dropdownData}
                        setSearchData={setSearchHC}
                        setSelectedData={setAddedHC}
                        selectedData={addedHC}
                        className={'w-50 mt-0'}
                        isOpen={openDropdown === 1}
                        onToggle={() => handleDropdownToggle(1)}
                    />
                    <button
                        onClick={() => setNewHC((pre) => !pre)}
                        className='ssc-primary-green-btn button-green-filled'
                    >
                        {EDIT.BUTTON_TEXT.ADD_NEW_HC}
                    </button>
                    {(hcToDelete.length > 0 ) && <DeleteButton handleClick={deleteHC} width={20} height={20} />}
                </div>

                <div className='d-flex justify-content-end'>
                    <button
                        type='button'
                        className='btn-close mt-2'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={handleClose}
                    />
                </div>
            </div>

            <div className='scroll-manage-platform mt-2'>
            {newHC && <AddHiringClient setNewHC={setNewHC} setAddedHC={setAddedHC} />}
            {hiringClientData.filter((pf) => pf?.id === tpa?.id)[0]?.hiringClients ? (
                <table className='table mt-3'>
                    <thead>
                        <tr>
                            <th className='edit-box-row'>Order</th>
                            <th>Hiring Client Name</th>
                            <th>Grade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hiringClientData
                            .filter((pf) => pf.id === tpa.id)[0]
                            .hiringClients.map((hc) => {
                                return (
                                        <tr key={hc.id}>
                                            <td className='edit-box-row'>
                                                {tpa?.hiringClients?.some((HC) => HC.id === hc.id) ? (
                                                    <input
                                                        onChange={() => handleSelect(hc.id)}
                                                        type='checkbox'
                                                        className='cursor-pointer'
                                                        checked={hcToDelete.includes(hc.id) ? true : false}
                                                    />
                                                ) : (
                                                    <button
                                                        onClick={() => removeItem(hc)}
                                                        className='btn-close'
                                                    ></button>
                                                )}
                                            </td>
                                            <td>{hc.name}</td>
                                            <td>
                                                <div className='input-set w-100 input-set-gap'>
                                                    <input
                                                        type='text'
                                                        name='grade-text'
                                                        onChange={(e) => updateGradeInHiringClientData(e, hc, setHiringClientData)}
                                                        placeholder={'Enter Grade'}
                                                        value={hc.hiring_client_grade || ''}
                                                        className={`form-control max-width-40-percent`}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                );
                            })}
                    </tbody>
                </table>
                ) : (
                    <div className='text-special text-center ms-5 mt-3'>{EDIT.BUTTON_TEXT.NO_HC}</div>
                )}
            </div>
            <div className='d-flex align-items-center justify-content-end mb-1 pt-lg-3 '>
                <button
                    onClick={handleSaveHC}
                    className='ssc-primary-green-btn button-green-filled form-button d-flex align-items-center justify-content-center border-0'
                >
                    {EDIT.BUTTON_TEXT.SAVE}
                </button>
            </div>
            <ConfirmationBox
                setConfirm={setConfirm}
                openConfirmationBox={openConfirmationBox}
                setOpenConfirmationBox={setOpenConfirmationBox}
                customMessage={EDIT.POPUP_MSG.ARE_YOU_SURE}
            />
        </div>
    );
};

HiringClientSection.propTypes = {
    id: PropTypes.string,
    handleClose: PropTypes.func,
    realData: PropTypes.array,
    tpa: PropTypes.object,
    hiringClientData: PropTypes.array,
    setHiringClientData: PropTypes.func,
    debouncedApiCall: PropTypes.func,
};