import React, { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { GreenStar, BlanckStar, FeedbackCross } from '../../../assets/svg';
import { CUSTOMER_DASHBOARD, SUCCESS } from '../../../constants';
import { ButtonComponent } from '../../shared';
import { createCustomerFeedback, popup } from '../../../utils';

const CustomerFeedback = () => {
    const selectedAccountName = useSelector((state) => state.accountArray.selectedAccountName);

    const [form_data, setFormData] = useState({
        open: false,
        rating: 0,
        hover_index: null,
        selected_options: [],
        comment: '',
        enable_submit: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...form_data,
            [name]: value,
        });
    };

    const handleStarClick = (index) => {
        setFormData({
            ...form_data,
            rating: index + 1,
            enable_submit: true,
        });
    };

    const handleMouseOver = (index) => {
        setFormData({
            ...form_data,
            hover_index: index + 1,
        });
    };

    const handleMouseOut = () => {
        setFormData({
            ...form_data,
            hover_index: null,
        });
    };

    const handleOptionClick = (option) => {
        const is_selected = form_data.selected_options.includes(option);
        setFormData({
            ...form_data,
            selected_options: is_selected
                ? form_data.selected_options.filter((selected) => selected !== option)
                : [...form_data.selected_options, option],
        });
    };

    const handleSubmit = async () => {
        const data = {
            rating: form_data.rating,
            improvements: form_data.selected_options,
            feedback: form_data.comment,
            account_name: selectedAccountName
        };

        createCustomerFeedback({ data }).then((response)=>{
            if (response.status === 'success') {
                popup(
                    SUCCESS.SUCCESS_TOAST,
                    response.message,
                );
            }
            handleClose();
        }).catch((error)=>{
            popup('error', error.message);
            handleClose();
        })
    };

    const handleClose = () => {
        setFormData({
            ...form_data,
            open: false,
            rating: 0,
            selected_options: [],
            comment: '',
            enable_submit: false,
        });
    };

    return (
        <div className='w-100 ssc-feedback'>
            <Button
                className='ssc-secondary-white-btn w-100'
                onClick={() => setFormData({ ...form_data, open: !form_data.open })}
                aria-expanded={form_data.open}
                aria-controls='MenuSidebarMobileView3'
            >
                Leave Feedback
            </Button>
            <Collapse
                in={form_data.open}
                className='collapse navbar-collapse bg-white d-block'
                id='MenuSidebarMobileView3'
            >
                <div>
                    <div className='d-flex align-items-center justify-content-between mb-4'>
                        <h2 className='mb-0'>Feedback</h2>
                        <ButtonComponent
                            className='border-0 bg-white p-0 d-flex align-items-start'
                            type='button'
                            clickHandler={handleClose}
                        >
                            <FeedbackCross />
                        </ButtonComponent>
                    </div>
                    <div className='rating'>
                        <p className='mb-3'>Provide feedback on how well App has worked</p>
                        <div className='ssc-rating-set d-flex align-items-center'>
                            {[...Array(5)].map((_, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleStarClick(index)}
                                    onMouseOver={() => handleMouseOver(index)}
                                    onMouseOut={handleMouseOut}
                                >
                                    {index <
                                    (form_data.hover_index !== null
                                        ? form_data.hover_index
                                        : form_data.rating) ? (
                                        <GreenStar width={18} height={17} />
                                    ) : (
                                        <BlanckStar width={18} height={17} />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='ssc-enchanced'>
                        <h3 className='mb-4'>Tell us what can be improved?</h3>
                        <div className='d-flex align-items-center flex-wrap pills-set'>
                            {CUSTOMER_DASHBOARD.FEEDBACK_OPTIONS.map((option, index) => (
                                <div
                                    key={index}
                                    className={`pills d-flex align-items-center justify-content-center ${form_data.selected_options.includes(option) ? 'active' : ''}`}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='ssc-comments mb-4'>
                        <label>Comments</label>
                        <textarea
                            name='comment'
                            id=''
                            cols='30'
                            rows='10'
                            placeholder='Please add any additional comments'
                            value={form_data.comment}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                    <ButtonComponent
                        className={'ssc-primary-green-btn'}
                        clickHandler={handleSubmit}
                        disabled={!form_data.enable_submit}
                    >
                        Submit
                    </ButtonComponent>
                </div>
            </Collapse>
        </div>
    );
};

export default CustomerFeedback;
