import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDebounce } from '../../../hooks';
import { DeleteButton, ConfirmationBox } from '../../shared';
import { AUTH, INFO, ROLES, USER } from '../../../constants';
import { InputBoxWithSearch } from '../DocumentRequest/InputBoxWithSearch';
import { getAllUser, popup, createAccountUser, deleteAccountUser } from '../../../utils';
import { CreateUser } from './CreateUser';
import { Edit } from '../../../assets/svg';

export const ManageUserBox = ({ data, setReload }) => {
    const [editUser, setEditUser] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [selectUser, setSelectedUser] = useState('');
    const [addNewUser, setAddNewUser] = useState('');
    const [searchUser, setSearchUser] = useState('');
    const [users, setUsers] = useState(data.account.users ? data.account.users : []);
    const [response, setResponse] = useState([]);
    const [checkedUsers, setCheckedUsers] = useState([]);
    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(null);
    const [primaryUser, setPrimaryUser] = useState(
        users.find((user) => user.is_primary === 1) || null,
    );
    const [changesMade, setChangesMade] = useState(false);
    const debouncedApiCall = useDebounce(() => {
        getAllUser({
            offset: 1,
            limit: 5,
            search: searchUser,
        })
            .then((res) => {
                if (res?.data) setResponse(res.data);
            })
            .catch((error) => {
                popup('error', error.message);
            });
    }, 500);

    useEffect(() => {
        debouncedApiCall();
    }, [searchUser]);

    const handleAddSelectedUser = (user) => {
        if (user) {
            if (users.some((u) => u.id === user.id)) {
                popup('error', USER.USER_ALREADY_EXIST);
            } else {
                setUsers((prevUsers) => [...prevUsers, user]);
                setChangesMade(true);
            }
        }
    };

    useEffect(() => {
        if (addNewUser) {
            handleAddSelectedUser(addNewUser);
            setAddNewUser('');
        } else if (selectUser) {
            handleAddSelectedUser(selectUser);
            setSelectedUser('');
        }
    }, [addNewUser, selectUser]);

    const handleSetPrimary = async () => {
        if (checkedUsers.length === 1 && primaryUser?.id !== checkedUsers[0].id) {
            setPrimaryUser(checkedUsers[0]);
            setUsers(
                users.map((u) => ({
                    ...u,
                    is_primary: u.id === checkedUsers[0].id ? 1 : 0,
                })),
            );
            setCheckedUsers([]);
            setChangesMade(true);
        }
    };

    const handleDeleteUser = () => {
        setUsers(users.filter((u) => !checkedUsers.some((cu) => cu.id === u.id)));
        setCheckedUsers([]);
        setChangesMade(true);
    };

    const handleCheckboxChange = (user) => {
        if (checkedUsers.some((cu) => cu.id === user.id)) {
            setCheckedUsers(checkedUsers.filter((cu) => cu.id !== user.id));
        } else {
            setCheckedUsers([...checkedUsers, user]);
        }
    };

    const handleSaveChanges = () => {
        setOpenConfirmationBox(true);
    };

    const saveData = () => {
        if (users.length) {
            const new_users = users.filter((user) => !data.account.users.some(u => u.id === user.id));
            const body = users.map((user) => ({
                account_id: data.account.account_id,
                user_id: user.id,
                is_primary: user.id === primaryUser?.id ? 1 : 0,
            }));
            createAccountUser({ users: body, new_users: new_users.map((user)=> ({...user, account_id: data.account.account_id, account_name: data?.account?.name }))})
                .then(() => {
                    popup('success', USER.USERS_UPDATED);
                    setConfirm(false);
                    setUsers(
                        users.map((user) => ({
                            ...user,
                            isNew: false,
                        })),
                    );
                    setReload(true);
                    setChangesMade(false);
                })
                .catch((error) => {
                    popup('error', error.message);
                });
        } else if (users.length === 0) {
            const body = {
                account_id: data.account.account_id,
            };
            deleteAccountUser({ data: body })
                .then(() => {
                    popup('success', USER.USERS_DELTED);
                    setChangesMade(false);
                })
                .catch((error) => {
                    popup('error', error.message);
                });
        }
    };

    useEffect(() => {
        if (confirm) {
            saveData();
        }
    }, [confirm]);

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prev) => (prev === id ? null : id));
    };
    const handleEdit = () => {
        setEditUser(true);
    };
    const handleClose = () => {
        setUsers(data.account.users ? data.account.users : []); // reset the users to initial state
        setEditUser(false);
        setNewUser(false);
        setChangesMade(false);
    };
    return (
        <div className='row'>
            <div className='col-12'>
                <div className='ps-lg-3 d-flex flex-column'>
                    <div className='tabs-set'>
                        <div className='d-flex justify-content-end '>
                            <div style={{ height: '14px' }}>
                                <Edit className='mx-2 cursor-pointer' clickHandler={handleEdit} />
                            </div>
                        </div>
                        <div className={`h-auto ${editUser ? 'hollow-box' : ''} mt-4`}>
                            {editUser && (
                                <div
                                    className={`wrapper  ps-1 pe-1 position-relative d-flex align-items-center gap-3`}
                                    id='wrapper'
                                >
                                    <div className='d-flex align-items-center  flex-grow-1 p-3'>
                                        <InputBoxWithSearch
                                            placeholder={'Select Existing User'}
                                            dropdownData={response}
                                            setSearchData={setSearchUser}
                                            setSelectedData={setSelectedUser}
                                            selectedData={selectUser}
                                            className={'w-50 mb-0'}
                                            isOpen={openDropdown === 1}
                                            onToggle={() => handleDropdownToggle(1)}
                                        />
                                        <button
                                            className='ssc-primary-green-btn button-green-filled ms-3'
                                            onClick={() => setNewUser(!newUser)}
                                        >
                                            {USER.ADD_USER}
                                        </button>
                                    </div>

                                    <div className='me-3 mb-3 d-flex  justify-content-end'>
                                        <button
                                            type='button'
                                            className='btn-close mt-2'
                                            data-bs-dismiss='modal'
                                            aria-label='Close'
                                            onClick={handleClose}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className='scroll-create-user'>
                                {newUser && (
                                    <CreateUser
                                        setAddNewUser={setAddNewUser}
                                        setNewUser={setNewUser}
                                    />
                                )}
                                {users.length !== 0 && (
                                    <div className='mb-1 input-set w-100 d-flex justify-content-end align-items-center gap-3'>
                                        {checkedUsers.length > 0 && (
                                            <>
                                                <DeleteButton
                                                    handleClick={handleDeleteUser}
                                                    width={20}
                                                    height={20}
                                                />
                                                <button
                                                    className='ssc-primary-green-btn button-green-filled'
                                                    onClick={handleSetPrimary}
                                                    disabled={
                                                        checkedUsers.length !== 1 ||
                                                        (checkedUsers.length === 1 &&
                                                            checkedUsers[0].is_primary === 1)
                                                    }
                                                >
                                                    {USER.SET_AS_PRIMARY_USER}
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                                {users.length === 0 && !newUser && (
                                    <div
                                        className='mt-4 vh-50 d-flex justify-content-center'
                                        style={{ fontSize: '16px' }}
                                    >
                                        {INFO.NO_USER_FOUND}
                                    </div>
                                )}
                                {users.length !== 0 && (
                                    <table className='table mt-3'>
                                        <tbody>
                                            {users
                                                .filter((user) => user.code !== ROLES.CSM)
                                                .map((user, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {editUser ? (
                                                                <input
                                                                    type='checkbox'
                                                                    className='cursor-pointer'
                                                                    checked={checkedUsers.some(
                                                                        (cu) => cu.id === user.id,
                                                                    )}
                                                                    onChange={() =>
                                                                        handleCheckboxChange(user)
                                                                    }
                                                                />
                                                            ) : (
                                                                <span>{index + 1}.</span>
                                                            )}
                                                        </td>
                                                        <td>{user.full_name}</td>
                                                        <td>{user.email}</td>
                                                        <td>
                                                            {user.is_primary === 1
                                                                ? USER.PRIMARY_USER_BUTTON
                                                                : USER.SECONDARY_USER}
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                )}

                                <ConfirmationBox
                                    setConfirm={setConfirm}
                                    openConfirmationBox={openConfirmationBox}
                                    setOpenConfirmationBox={setOpenConfirmationBox}
                                    customMessage={USER.ARE_YOU_SURE}
                                />
                            </div>
                            {changesMade && (
                                <div className='ms-auto m-4 d-flex justify-content-end '>
                                    <button
                                        className='save-change-button border-0'
                                        onClick={handleSaveChanges}
                                    >
                                        {AUTH.SAVE_CHANGES}
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ManageUserBox.propTypes = {
    data: PropTypes.object,
    setReload: PropTypes.func,
};
