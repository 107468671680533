import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { formValidator, createHiringCLient, popup } from '../../../utils';
import { REGEX, VALIDATION, MAX_LENGTH, EDIT_CUSTOMER as EDIT } from '../../../constants';

import { IconPlus } from '../../../assets/svg';

export const AddHiringClient = ({ setNewHC, setAddedHC }) => {
    const [isFormFocused, setIsFormFocused] = useState(false);

    const validationSchema = {
        name: {
            regex: REGEX.ACCOUNT_NAME,
            message: VALIDATION.INVALID_NAME,
            requiredMessage: VALIDATION.HIRING_NAME_REQUIRED
        }
    };
    const validateForm = () => setIsFormFocused(true);
    return (
        <>
            <div className='user-create-form'>
                <div className='text-end w-100'>
                    <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={() =>
                            setNewHC((prev) => {
                                return !prev;
                            })
                        }
                    />
                </div>
                <Formik
                    initialValues={{
                        name: ''
                    }}
                    validate={(values) => {
                        const errors = {};
                        formValidator({ values, errors, validationSchema });
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        const id = uuidv4();
                        createHiringCLient({
                            body: {
                                id: id,
                                name: values.name
                            },
                        })
                            .then((response) => {
                                setSubmitting(false);
                                setNewHC((prev) => {
                                    return !prev;
                                });
                                setAddedHC({
                                    id: id,
                                    name: values.name
                                });
                                popup('success', response.message);
                            })
                            .catch((error) => {
                                setSubmitting(false);
                                popup('error', error.message);
                            });
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setTouched,
                    }) => {
                        const isFormValid =
                            values.name && !errors.name;

                        return (
                            <div>
                                <form
                                    onSubmit={handleSubmit}
                                    className='d-flex justify-content-between mb-2 flex-column'
                                >
                                    <div
                                        onFocus={() => validateForm()}
                                        className='d-flex justify-content-between align-items-center'
                                    >
                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='mb-1 input-set w-100'>
                                                        <label htmlFor='name'>
                                                            {EDIT.FORM_FIELD.NAME}
                                                        </label>
                                                        <input
                                                            maxLength={MAX_LENGTH.NAME}
                                                            type='text'
                                                            name='name'
                                                            onChange={(e) => {
                                                                if (!touched.name) {
                                                                    setTouched({
                                                                        ...touched,
                                                                        name: true,
                                                                    });
                                                                }
                                                                handleChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                            className={`input-area-set form-control ${
                                                                isFormFocused &&
                                                                errors.name &&
                                                                touched.name &&
                                                                errors.name
                                                                    ? 'border border-danger-subtle border-1'
                                                                    : ''
                                                            }`}
                                                            placeholder={EDIT.PLACEHOLDER.HC_NAME}
                                                        />
                                                        {isFormFocused &&
                                                            touched.name &&
                                                            errors.name && (
                                                                <p className='error-message'>
                                                                    {errors.name}
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-end mb-1'>
                                            <button
                                                type='submit'
                                                disabled={isSubmitting || !isFormValid}
                                                className='ssc-primary-green-btn plus-icon-edit align-item-center'
                                            >
                                                <IconPlus />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        );
                    }}
                </Formik>
            </div>
        </>
    );
};

AddHiringClient.propTypes = {
    setNewHC: PropTypes.func,
    setAddedHC: PropTypes.func,
};
