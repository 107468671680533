import { createSlice } from '@reduxjs/toolkit';
import { LOCAL_STORAGE, REDUX, ROLES } from '../../constants';
import { getLocalStorageItem } from '../../utils';

const initialState = {
    isUserAdmin: false,
    firstName: '',
    lastName: '',
    fullName: '',
    imagePath: null
};

const slice = createSlice({
    name: REDUX.slice.USER,
    initialState,
    reducers: {
        setIsUserAdmin: (state) => {
            // get user role from local storage
            const userRole = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);

            // check if user role is admin, super admin or csm
            if ([ROLES.ADMIN, ROLES.SUPER_ADMIN, ROLES.CSM].includes(userRole)) return void(state.isUserAdmin = true);
            else return void(state.isUserAdmin = false);
        },
        setUserData: (state, action) => {
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.fullName = state.firstName + ' ' + state.lastName;
            state.imagePath = action.payload.imagePath;
        }
    },
});

export const { setIsUserAdmin, setUserData } = slice.actions;
export default slice.reducer;
