import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { CardRightArrow } from '../../../assets/svg';
import { CUSTOMER_DASHBOARD, ROUTES } from '../../../constants';
import { CustomDropdown, ViewAll } from '../../shared';
import { getHiringClients, handleScroll } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';

const CustomerDashboardHiringClients = ({ platforms }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const scrollBar = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const [selectedPlatformID, setSelectedPlatformID] = useState('');
    const [isAccountDropDownOpen, setIsAccountDropDownOpen] = useState(false);
    const [platformName, setPlatformName] = useState(null);
    const [hiringClients, setHiringClientsData] = useState([]);

    const customerId = useSelector((state) => state.accountArray.selectedAccount);

    const handleRedirect = (hc) => {
        navigate(ROUTES.CUSTOMER.HIRING_CLIENTS, { state: {id: hc.id }})
    };

    const handleScrolling = () => {
        handleScroll(setIsScrolling, scrollBar, 2000);
    };
    
    const getHiringClientsData = async () => {
        if(customerId) {
            dispatch(setLoaderVisibility(true));
            const hiringClient = await getHiringClients({
                account_id: customerId,
                platform_id: selectedPlatformID,
            });
            setHiringClientsData(hiringClient?.data);
            dispatch(setLoaderVisibility(false));
        }
    };

    useEffect(() => {
        getHiringClientsData();
    }, [selectedPlatformID])

    useEffect(() => {
        if(platforms && platforms.length)
            setSelectedPlatformID(platforms[0].id);
            setPlatformName(`${platforms[0]?.name}®`);
    }, [platforms])

    return (
        <div className='col-6 custom-col-set bottom-left'>
            <div className='h-100 card-container d-flex flex-column w-100 align-items-center p-lg-4 p-3'>
                <div className='card-title-area d-flex w-100 align-items-center justify-content-between'>
                    <h5>Hiring Clients</h5>
                    {platforms && platforms.length > 0 && (
                        <CustomDropdown
                            options={platforms.map(platform => ({ ...platform, name: `${platform.name}®`}))}
                            handleOptionSelect={(option) => {
                                setSelectedPlatformID(option?.id);
                                setPlatformName(option?.name);
                                setIsAccountDropDownOpen(false);
                            }}
                            isDropdownOpen={isAccountDropDownOpen}
                            setIsDropdownOpen={setIsAccountDropDownOpen}
                            selectedOption={platformName}
                        />
                    )}
                </div>
                <div onScroll={handleScrolling} className={`content-set-area flex-column d-flex w-100 align-items-start justify-content-start  ${isScrolling ? 'scrolling' : 'not-scrolling'}`}>
                    {platforms && platforms.length && hiringClients.length
                        ? hiringClients.map((hc, index) => (
                            <div
                                key={index}
                                className='content-set d-flex w-100 align-items-center justify-content-between cursor-pointer'
                                type='button'
                                onClick={() => handleRedirect(hc)}
                            >
                                <div>
                                    <p className='mb-0'>{hc.name}</p>
                                </div>
                                <div className='d-flex align-items-center justify-content-end'>
                                    <CardRightArrow width={16} height={16} />
                                </div>
                            </div>
                        ))
                        : CUSTOMER_DASHBOARD.HIRING_CLIENT_NOT_FOUND}
                </div>
                {hiringClients.length >= 20 && (
                    <div className='view-btn d-flex justify-content-start w-100'>
                        <ViewAll
                            className='ssc-card-btn2'
                            navigateTo={ROUTES.CUSTOMER.HIRING_CLIENTS}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

CustomerDashboardHiringClients.propTypes = { platforms: PropTypes.array };

export default CustomerDashboardHiringClients;
