import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import SingleRowRight from '../../../assets/img/icons/single-row-right.svg';
import { CUSTOMER_DASHBOARD, NAVIGATION_DATA_TYPES, ROUTES } from '../../../constants';
import { setNavigationState } from '../../../redux';

const CustomerTPABox = ({ tpaData }) => {
    const dispatch = useDispatch();
    const onClickPlatform = (platformDetails) => {
        dispatch(setNavigationState({ ...platformDetails, type: NAVIGATION_DATA_TYPES.PLATFORM }));
    };

    return (
        <div className='col-10 custom-col-set'>
            <div className='top-right h-100'>
            <div className='d-flex align-items-center justify-content-between mb-4'>
                <h3 className='mb-0'>{CUSTOMER_DASHBOARD.PLATFORMS}</h3>
            </div>

                <div className='row mx-0'>
                    {tpaData.length
                        ? tpaData.map((e) => {
                              const box_class_name = `w-100 label-box ${e.grade ?? 'grey'}-label d-flex align-items-center justify-content-between`;
                              const dot_class_name = `${e.grade ?? 'grey'}-dot dot me-3`;

                              return (
                                  <div key={e.id} className='col-xl-4 col-6 mb-3'>
                                      <Link
                                          onClick={() => onClickPlatform(e)}
                                          to={ROUTES.CUSTOMER.SCORE_CARD_REPORT}
                                          className='text-decoration-none'
                                      >
                                          <div className={box_class_name}>
                                              <div className='d-flex align-items-center'>
                                                  <div className={dot_class_name}></div>
                                                  <span>{e.name}</span> <sup>{'®'}</sup>
                                              </div>
                                              <img
                                                  src={SingleRowRight}
                                                  alt='icon'
                                                  width='10'
                                                  height='15'
                                              />
                                          </div>
                                      </Link>
                                  </div>
                              );
                          })
                        : CUSTOMER_DASHBOARD.PLATFORMS_NOT_FOUND}
                </div>
            </div>
        </div>
    );
};

CustomerTPABox.propTypes = {
    tpaData: PropTypes.array.isRequired,
};

export default CustomerTPABox;
