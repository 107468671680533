import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from '../../../constants';
import DashboardContent from './DashboardContent';
import { HiringClientListing } from '../HiringClientListing';
import { DocumentListing } from '../DocumentListing';
import {ScoreCardReport} from '../ScoreCardReport';
import { Notification } from '../../shared';
import { EditProfile } from '../../shared';

const RenderDashboard = (selectedTab) => {
    switch (selectedTab) {
        case ROUTES.CUSTOMER.DASHBOARD:
            return <DashboardContent />;
        case ROUTES.CUSTOMER.DOCUMENTS:
            return <DocumentListing/>;
        case ROUTES.CUSTOMER.SCORE_CARD_REPORT:
            return <ScoreCardReport/>;
        case ROUTES.CUSTOMER.HIRING_CLIENTS:
            return <HiringClientListing />;
        case ROUTES.CUSTOMER.NOTIFICATION:
            return <Notification />;
        case ROUTES.CUSTOMER.EDIT_PROFILE:
            return <EditProfile />;
        default:
            return <DashboardContent />;
    }
};

const ContentBox = ({ currentRoute }) => <div className='col-lg-10 ssc-main-content'>{RenderDashboard(currentRoute)}</div>;

ContentBox.propTypes = {
    currentRoute: PropTypes.string.isRequired,
};

export default ContentBox;
