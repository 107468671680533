/*
Contains constants related to application routes, 
such as route paths, route names, or route parameters.
*/

// all application routes
// export const ROUTES = {
//     HOME: '/',
//     EMPTY: '',
//     DASHBOARD: '/dashboard',
//     LOGIN: '/login',
//     SIGNUP: '/signup',
//     FORGOT_PASSWORD: '/forgot-password',
//     RESET_PASSWORD: '/reset-password',
//     ADMIN: '/dashboard/admin',
//     DOCUMENT_CATEGORY: '/document-category',
//     DOCUMENTS: '/documents',
//     CSM: '/csm-list',
//     CUSTOMER: '/customer',
//     DOCUMENT_REQUEST: '/document-request',
//     SCORE_CARD_REPORT: '/score-card-report',
//     HIRING_CLIENTS: '/hiring-clients',
// };

export const ROUTES = {
    HOME: '/',
    EMPTY: '',
    SERVER_ERROR: '/server_error',
    CUSTOMER: {
        LOGIN: '/portal/login',
        SIGNUP: '/portal/signup',
        FORGOT_PASSWORD: '/forgot_password',
        RESET_PASSWORD: '/reset_password',
        DEFAULT: '/customer',
        DASHBOARD: '/customer/dashboard',
        DOCUMENTS: '/customer/documents',
        SCORE_CARD_REPORT: '/customer/score_card_report',
        HIRING_CLIENTS: '/customer/hiring_clients',
        UPLOAD: '/upload',
        NOTIFICATION: '/customer/notification',
        EDIT_PROFILE: '/customer/edit_profile',
    },
    ADMIN: {
        LOGIN: '/login',
        DEFAULT: '/admin',
        DASHBOARD: '/admin/dashboard',
        DOCUMENT_CATEGORY: '/admin/document_category',
        DOCUMENTS: '/admin/documents',
        ADMIN: '/admin/admin_list',
        CSM: '/admin/csm_list',
        CUSTOMER: '/admin/customer',
        CUSTOMER_EDIT: '/admin/customer/edit',
        DOCUMENT_REQUEST: '/admin/document_request',
        SCORE_CARD_REPORT_ADMIN: '/admin/score_card_report',
        NEWS_FEED_ADMIN: '/admin/news_feed',
        NOTIFICATION: '/admin/notification',
        ADMIN_SCORE_CARD_LISTING: '/admin/score_card_listing',
        EDIT_PROFILE: '/admin/edit_profile',
        ADMIN_REGULATORY_ISSUE: '/admin/regulatory_issue',
        CUSTOMER_HIRING_CLIENT_ORDER: '/admin/hiring_client_order'
    }
};
