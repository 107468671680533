import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Edit, IconPlus } from '../../../assets/svg';
import { DeleteButton } from '../../shared';
import { getAllPlatforms, popup } from '../../../utils';
import { InputBoxWithSearch } from '../DocumentRequest/InputBoxWithSearch';
import { HiringClientSection } from './HiringClientSection';
import { EDIT_CUSTOMER as EDIT, SYMBOL } from '../../../constants';

export const ManagePlatformBox = ({ accountData, debouncedApiCall }) => {
    const [realData, setRealData] = useState([]);
    const [data, setData] = useState([]);
    const [editPlatform, setEditPlatform] = useState(false);
    const [tpa, setTpa] = useState('');
    const [platformBox, setPlatformBox] = useState(true);
    const [searchPlatform, setSearchPlatform] = useState('');
    const [dropdownData, setDropDownData] = useState([]);
    const [addedPlatform, setAddedPlatform] = useState();
    const [openDropdown, setOpenDropdown] = useState(null);

    const handlePlatformDelete = () => {
        if (tpa) {
            setData((pre) => pre.filter((pf) => pf.id !== tpa.id));
            setTpa(data[0]);
        } else {
            popup('error', EDIT.POPUP_MSG.PLEASE_SELECT_PLATFORM);
        }
    };

    const getPlatformData = () => {
        getAllPlatforms(1, 5, searchPlatform)
            .then((res) => setDropDownData(res.data))
            .catch((err) => popup('error', err.msg));
    };

    const handleAddPlatForm = () => {
        setEditPlatform(true);
        setPlatformBox(false);
        getPlatformData();
    };

    const handleEdit = () => {
        setEditPlatform(true);
        setPlatformBox(true);
    };
    const handleClose = () => {
        setTpa('');
        setEditPlatform(false);
        debouncedApiCall();
        setData((pre) => {
            return { ...pre, platformsWithHC: [...accountData.platformsWithHC] };
        });
    };
    const saveAddedPlatform = () => {
        if (addedPlatform) {
            const isPlatformExist = data.find((platform) => platform.id === addedPlatform.id);

            isPlatformExist
                ? popup('error', EDIT.POPUP_MSG.PLATFORM_EXIST)
                : (setData((pre) => {
                      return [...pre, addedPlatform];
                  }),
                  setTpa(addedPlatform),
                  setPlatformBox(true),
                  setAddedPlatform(''));
        } else {
            popup('error', EDIT.POPUP_MSG.PLEASE_SELECT_PLATFORM);
        }
    };

    const handleDropdownToggle = (id) => {
        setOpenDropdown((prev) => (prev === id ? null : id));
    };

    useEffect(() => {
        getPlatformData();
    }, [searchPlatform]);

    useEffect(() => {
        if (accountData && accountData.platformsWithHC) {
            setData(accountData?.platformsWithHC);
            setRealData(accountData?.platformsWithHC);
            setTpa(accountData?.platformsWithHC?.[0]);
        }
    }, [accountData]);

    return (
        <div className='tabs-set h-25'>
            <div className='d-flex justify-content-end '>
                <div style={{ height: '14px' }}>
                    {!editPlatform && (
                        <Edit className='mx-2 cursor-pointer' clickHandler={handleEdit} />
                    )}
                </div>
            </div>
            <div className='mt-4'>
                {/* Platform Section */}
                <div className='d-flex flex-column justify-content-between align-item-center ps-lg-2 pe-lg-2 '>
                    <div className='d-flex justify-content-start  align-item-center gap-3 '>
                        {data.length ? (
                            <div className='d-flex w-50 justify-content-between align-item-center'>
                                <div className='d-flex w-50 align-item-center gap-2 justify-content-start'>
                                    {data.map((platform) => (
                                        <span
                                            key={platform.id}
                                            className={`cursor-pointer ${platform.id === tpa.id ? 'ssc-primary-green-btn' : 'ssc-secondary-white-btn'} 
                                button-green-filled`}
                                            onClick={() => setTpa(platform)}
                                        >
                                            {platform.name ? platform.name : ''}
                                            <sup>{SYMBOL.REGISTERED}</sup>
                                        </span>
                                    ))}
                                </div>
                                {tpa && editPlatform && (
                                    <DeleteButton
                                        height={20}
                                        width={20}
                                        handleClick={handlePlatformDelete}
                                    />
                                )}
                            </div>
                        ) : (
                            !editPlatform && (
                                <span className='d-flex align-items-center ms-1 '>
                                    {EDIT.BUTTON_TEXT.NO_PLATFORM}
                                </span>
                            )
                        )}
                    </div>
                    {editPlatform && (
                        <div
                            className={`${!platformBox ? 'w-50' : ''} d-flex justify-content-start align-item-center gap-3 `}
                        >
                            {platformBox ? (
                                <div className='d-flex align-items-center w-100 justify-content-between   '>
                                    <button
                                        onClick={handleAddPlatForm}
                                        className='ssc-primary-green-btn button-green-filled'
                                    >
                                        {EDIT.BUTTON_TEXT.ADD_NEW_PLATFORM}
                                    </button>
                                </div>
                            ) : (
                                <div className='d-flex align-items-center w-100 justify-content-between pt-lg-3 '>
                                    <InputBoxWithSearch
                                        label={''}
                                        placeholder={'Select Platforms'}
                                        dropdownData={dropdownData}
                                        setSearchData={setSearchPlatform}
                                        setSelectedData={setAddedPlatform}
                                        selectedData={addedPlatform}
                                        className={'w-50 '}
                                        isOpen={openDropdown === 1}
                                        onToggle={() => handleDropdownToggle(1)}
                                        addRegisteredSymbol={true}
                                    />
                                    <div
                                        onClick={saveAddedPlatform}
                                        className='cursor-pointer ssc-primary-green-btn  mt-0 plus-icon-edit align-item-center'
                                    >
                                        <IconPlus />
                                    </div>
                                    <div
                                        onClick={() => {
                                            setPlatformBox(true);
                                        }}
                                        className='cursor-pointer cancel-box border border-1 ssc-primary-green-btn mt-0 plus-icon-edit align-item-center'
                                    >
                                        X
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {/* Hiring Client Section if edit */}
                {editPlatform && tpa && (
                    <HiringClientSection
                        handleClose={handleClose}
                        id={accountData.account.account_id}
                        tpa={tpa}
                        realData={realData}
                        hiringClientData={data}
                        setHiringClientData={setData}
                        debouncedApiCall={debouncedApiCall}
                    />
                )}
                {/* Hiring Client Section if no edit */}
                {tpa && !editPlatform && (
                    <div className='border user-create-form mt-2 ps-4 pe-4 mb-5'>
                        <div
                            className={`wrapper position-relative d-flex align-items-center gap-3 justify-content-between`}
                            id='wrapper'
                        >
                            {tpa?.hiringClients?.length ? (
                                <table className='table mt-3'>
                                    <thead>
                                            <tr>
                                                <th className='edit-box-row'>Order</th>
                                                <th>Hiring Client Name</th>
                                                <th>Grade</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        {tpa.hiringClients.map((hc, index) => {
                                            return (
                                                <tr key={hc.id}>
                                                    <td className='edit-box-row'>{index + 1}.</td>
                                                    <td>{hc.name}</td>
                                                    <td>{hc.hiring_client_grade || '-'}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            ) : (
                                <span className='text-special text-center ms-5'>
                                    {EDIT.BUTTON_TEXT.NO_HC}
                                </span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

ManagePlatformBox.propTypes = {
    accountData: PropTypes.object,
    setReload: PropTypes.func,
};
