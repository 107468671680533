import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';

import { SSCLogo, EyeIcon, OpenEyeIcon } from '../../../assets/svg';
import { AUTH, LOCAL_STORAGE, ROUTES, VALIDATION, MAX_LENGTH, LOGIN_VALIDATION } from '../../../constants';
import 'react-toastify/dist/ReactToastify.css';
import { formValidator, popup, setLocalStorageItem, customerLogin, encryptPassword } from '../../../utils';
import { setAccounts, setUserData, setLoaderVisibility } from '../../../redux';
import { useDebounce } from '../../../hooks';

export function Login() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isFormFocused, setIsFormFocused] = useState(false);

    const login = useDebounce((values, setSubmitting, resetForm) => {
        customerLogin({
            data: { ...values, password: encryptPassword(values.password) },
        })
            .then((response) => {
                if(response.data.accounts.length === 0){
                    dispatch(setLoaderVisibility(false));
                    setSubmitting(false);
                    resetForm();
                    popup("error",VALIDATION.NO_CUSTOMER_ASSIGNED);
                    return navigate(ROUTES.CUSTOMER.LOGIN);
                }

                const accountArray = response.data.accounts;
                dispatch(setAccounts(accountArray));
                setLocalStorageItem(LOCAL_STORAGE.FULL_NAME, response.data.full_name);
                setLocalStorageItem(LOCAL_STORAGE.AUTH_TOKEN, response.data.auth_token);
                setLocalStorageItem(LOCAL_STORAGE.ACCOUNT_ID, response.data.accounts[0]?.id);
                setLocalStorageItem(LOCAL_STORAGE.USER_ID, response.data.id);
                setLocalStorageItem(LOCAL_STORAGE.EMAIL, response.data.email);
                setLocalStorageItem(LOCAL_STORAGE.REFRESH_TOKEN, response.data.refresh_token);

                dispatch(setUserData({
                    firstName: response?.data?.first_name,
                    lastName: response?.data?.last_name,
                    imagePath: response?.data?.image || null
                }));

                setSubmitting(false);
                dispatch(setLoaderVisibility(false));
                navigate(ROUTES.CUSTOMER.DASHBOARD);
            })
            .catch((error) => {
                setSubmitting(false);
                dispatch(setLoaderVisibility(false));
                popup('error', error.message);
            });
    }, 1000);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest('.user-input-section') === null)
                setTimeout(() => {
                    setIsFormFocused(false);
                }, 100);
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const validateForm = () => setIsFormFocused(true);

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validate={(values) => {
                const errors = {};
                formValidator({ values, errors, validationSchema: LOGIN_VALIDATION.SCHEMA });
                return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                dispatch(setLoaderVisibility(true));
                login(values, setSubmitting, resetForm);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                dirty,
                isSubmitting,
                setTouched,
            }) => {
                return (
                    <>
                        <form
                            onSubmit={handleSubmit}
                            className='w-100 cust-form d-flex align-items-center justify-content-center flex-column'
                        >
                            <div className='extra-spacing-rl'>
                                <SSCLogo width={233} height={44} className={'mb-4'} />
                                <h6 className='text-center'>{AUTH.LOGIN_WITH_YOUR_EMAIL}</h6>
                            </div>
                            <div
                                className='user-input-section w-100'
                                onFocus={() => validateForm()}
                            >
                                <div className='input-set w-100 input-set-gap'>
                                    <label htmlFor=''>{AUTH.EMAIL_ADDRESS}</label>
                                    <div className='position-relative'>
                                        <input
                                            maxLength={MAX_LENGTH.EMAIL}
                                            type='email'
                                            name='email'
                                            onChange={(e) => {
                                                !touched.email &&
                                                    setTouched({ ...touched, email: true });
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            className={`form-control ${isFormFocused && errors.email && touched.email && errors.email ? 'border border-danger-subtle border-1' : ''}`}
                                            placeholder={AUTH.ENTER_EMAIL_ADDRESS}
                                        />
                                        {isFormFocused && touched.email && errors.email && (
                                            <p className='error-message'>{errors.email}</p>
                                        )}
                                    </div>
                                </div>
                                <div className='input-set w-100'>
                                    <label htmlFor=''>{AUTH.PASSWORD}</label>
                                    <div className='position-relative'>
                                        <input
                                            maxLength={MAX_LENGTH.PASSWORD_FIELD}
                                            type={passwordVisible ? 'text' : 'password'}
                                            name='password'
                                            onChange={(e) => {
                                                !touched.password &&
                                                    setTouched({ ...touched, password: true });
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            className={`form-control ${isFormFocused && errors.password && touched.password && errors.password ? 'border border-danger-subtle border-1' : ''}`}
                                            placeholder={AUTH.ENTER_PASSWORD}
                                        />
                                        {isFormFocused && touched.password && errors.password && (
                                            <p className='error-message'>{errors.password}</p>
                                        )}
                                        <button
                                            type='button'
                                            onClick={() =>
                                                setPasswordVisible((visible) => !visible)
                                            }
                                            className='eye-icon top-12px m-0 border-0 position-absolute'
                                        >
                                            {passwordVisible ? (
                                                <OpenEyeIcon width={16} height={16} />
                                            ) : (
                                                <EyeIcon />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-100 extra-spacing-rl'>
                                <button
                                    type='submit'
                                    className={'ssc-primary-green-btn'}
                                    disabled={
                                        isSubmitting || !dirty || errors.email || errors.password
                                    }
                                >
                                    {AUTH.LOGIN}
                                </button>
                            </div>

                            <span className='login-page-forgot-reset-link'>
                                <Link
                                    state={{ isReset: true }}
                                    to={ROUTES.CUSTOMER.FORGOT_PASSWORD}
                                    className='forget-link  text-end mt-4'
                                >
                                    {AUTH.RESET_PASSWORD}
                                </Link>
                                <Link
                                    to={ROUTES.CUSTOMER.FORGOT_PASSWORD}
                                    className='forget-link  text-end mt-4'
                                >
                                    {AUTH.FORGOT_PASSWORD}
                                </Link>
                            </span>
                            <div className='mt-4 d-flex align-items-center justify-content-center form-bottom'>
                                <p className='mb-0'>{AUTH.NOT_REGISTERED}</p>
                                <Link to={ROUTES.CUSTOMER.SIGNUP} className='link'>
                                    {AUTH.REGISTER_NOW}
                                </Link>
                            </div>
                        </form>
                    </>
                );
            }}
        </Formik>
    );
}
