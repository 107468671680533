import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ScoreCardDeleteModal from './ScoreCardDeleteModal';
import { Edit, Delete, Email } from '../../../assets/svg';
import { SCORECARD_REPORT, ROUTES, INFO, ROLES, LOCAL_STORAGE } from '../../../constants';
import { ConfirmationBox, TooltipComponent } from '../../shared';
import { getLocalStorageItem, popup, sendReport } from '../../../utils';
import { setLoaderVisibility } from '../../../redux';

export const AdminScoreCardListingOperation = ({ data, debouncedApiCall }) => {

    //declaration

    const navigate = useNavigate();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [selectedData, setSelected] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);
    const dispatch = useDispatch();
    const roleCode = getLocalStorageItem(LOCAL_STORAGE.ROLE_CODE);

    const [confirm, setConfirm] = useState(false);
    const [openConfirmationBox, setOpenConfirmationBox] = useState(false);
    const [accountId, setAccountId] = useState(null);
    
    // functions

    const handleEditClick = (editData) => {
        navigate(ROUTES.ADMIN.SCORE_CARD_REPORT_ADMIN, {
            state: {
                accountUserDetails: {
                    id: editData.account_id,
                    name: editData.account_name,
                    platform_id: editData.platform_id
                }
            }
        });
    };

    const handleDeleteModalClick = (data) => {
        setSelected(data);
        setIsDeleteModal(true);
    };

    const handleSendReport = async (account_id)=>{
        setOpenConfirmationBox(true);
        setAccountId(account_id);
    }

    useEffect(() => {
        async function handleSendReport() {
            if(confirm) {
                setIsDisabled(true);
                try {
                    dispatch(setLoaderVisibility(true));
                    await sendReport({ id: accountId });
                    dispatch(setLoaderVisibility(false));
                    popup('success', INFO.SEND_REPORT_CSM);
                } catch (error) {
                    dispatch(setLoaderVisibility(false));
                    popup('error', error.message);
                }
                setIsDisabled(false);
            }
        }
        handleSendReport();
    }, [confirm]);

    return (
        <>
            <div className='d-flex align-items-center justify-content-start options-set'>
                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-edit'
                    onClick={() => handleEditClick(data)}
                >
                    <Edit />
                </button>

                <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-del'
                    onClick={() => handleDeleteModalClick(data)}
                >
                    <Delete />
                </button>
                {[ROLES.CSM].includes(roleCode) && <button
                    className='border-0 p-0 bg-white'
                    type='button'
                    data-bs-toggle='modal'
                    data-bs-target='#exampleModal-del'
                    onClick={() => handleSendReport(data.account_id)}
                    disabled={isDisabled}
                >
                    <TooltipComponent tooltipText='Send Report'>
                        <Email />
                    </TooltipComponent>
                </button>}
            </div>

            {isDeleteModal && (
                <ScoreCardDeleteModal
                    isDeleteScorecardByAccountAndPlatform = {true}
                    isDeleteModal={isDeleteModal}
                    setIsDeleteModal={setIsDeleteModal}
                    selectedData={selectedData}
                    deleteButtonName={SCORECARD_REPORT.SCORE_CARD_LISTING_PAGE.DELETE_BUTTON}
                    debouncedApiCall={debouncedApiCall}
            />
            )}
            <ConfirmationBox
                setConfirm={setConfirm}
                openConfirmationBox={openConfirmationBox}
                setOpenConfirmationBox={setOpenConfirmationBox}
                customMessage={INFO.CONFIRM_SEND_REPORT}
            />
        </>
    );
};

AdminScoreCardListingOperation.propTypes = {
    data: PropTypes.shape({
        account_id: PropTypes.string.isRequired,
        account_name: PropTypes.string.isRequired
    }).isRequired,
    debouncedApiCall: PropTypes.func
};