/* contains constants related to messages and notifications, 
such as success messages, error messages, warning messages */

export const ERROR = {
    SIGNUP: 'Signup Failed!',
    INVALID_LOCAL_STORAGE_KEY_OR_VALUE: 'Invalid key or value provided for local storage.',
    INTERNAL_SERVER_ERROR: 500,
    SESSION_EXPIRED: 'Session expired. Please login again',
    JWT_EXPIRED: 'jwt expired',
    HIRING_CLIENT_ALREADY_EXISTS: 'Hiring client already exists',
    SELECT_ALL_FIELDS: 'Select all fields',
    SCORECARD_ALREADY_EXISTS: 'Scorecard already exists',
    SELECT_CUSTOMER: 'Select a customer',
    NO_PLATFORM: 'No platform available',
    NO_HIRING_CLIENT_SELECTED: 'No hiring client selected',
    SELECT_PLATFORM_GRADE: 'Select a Platform Grade',
    SELECT_PLATFORM: 'Select a Platform',
    SELECT_HIRING_CLIENT: 'Select a Hiring Client',
    UNEXPECTED_ERROR: 'Unexpected Error occured',
};
export const SUCCESS = {
    SUCCESS_TOAST: 'success',
    SIGNUP: 'Signup successful!',
    CATEGORY: {
        CREATED: 'Category added successfully',
        UPDATED: 'Category updated successfully',
        DELETED: 'Category deleted',
    },
    SCORE_CARD_COLUMNS_ADMIN: {
        CREATED: 'Scorecard created successfully',
        UPDATED: 'Scorecard updated successfully',
        DELETED: 'Scorecard deleted',
        GRADE_UPDATED: 'Grade successfully updated for selected platform',
    },
};
export const INFO = {
    NO_HIRING_CLIENT_FOUND: 'No Hiring Clients found.',
    NO_DATA_AVAILABLE: 'No Data Available',
    PRIMARY_USER_UPDATED: 'Primary user updated',
    PRIMARY_USER_CREATED: 'Primary user created',
    PRIMARY_USER_REMOVED: 'Primary User removed',
    SELECTED_NONE: 'Please select an user',
    SELECTED_SAME_USER: 'Selected same user',
    NOTHING_TO_DELETE: 'Nothing to delete',
    SEND_REPORT_USER: 'Scorecard Report has been sent to your e-mail.',
    SEND_REPORT_CSM: "Scorecard Report has been sent to the user's e-mail.",
    NO_USER_FOUND: ' No user Found',
    NO_CSM_FOUND: ' No CSM Found',
    CONFIRM_SEND_REPORT: 'Do you want to send customer report?',
};
export const VALIDATION = {
    REQUIRED: 'Required',
    CUSTOMER_NAME: 'Invalid customer name',
    CUSTOMER_NAME_REQUIRED: 'Customer name is required',
    ACCOUNT_NAME_REQUIRED: 'Company name is required',
    ACCOUNT_NAME: 'Invalid Company name',
    FIRST_NAME_REQUIRED: 'First name is required',
    LAST_NAME_REQUIRED: 'Last name is required',
    EMAIL_REQUIRED: 'Email address is required',
    PHONE_REQUIRED: 'Phone number is required',
    PASSWORD_REQUIRED: 'Password is required',
    CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required',
    INVALID_EMAIL: 'Invalid email address',
    INVALID_PHONE: 'Invalid phone number',
    PASSWORD_MIN_LENGTH_ERR: 'Minimum password length: 10 characters',
    PASSWORD_MAX_LENGTH_ERR: 'Maximum password length: 16 characters',
    INVALID_NAME: 'Invalid name',
    INVALID_FIRST_NAME: 'Invalid first name',
    INVALID_LAST_NAME: 'Invalid last name',
    STRONG_PASSWORD_REQUIRED: 'Strong password required',
    PASSWORD_DONT_MATCH: "Passwords don't match",
    EMPTY_CATEGORY: 'Category cannot be empty',
    EMPTY_FEED: 'News feed cannot be empty',
    INVALID_CATEGORY_NAME: 'Invalid category name',
    FEED_LENGTH_LIMIT: 'News feed should not exceed 100 characters.',
    REGULATORY_TITLE: 'Invalid title',
    REGULATORY_CONTENT: 'Invalid content',
    REGULATORY_TITLE_REQUIRED: 'Title is required',
    REGULATORY_CONTENT_REQUIRED: 'Content is required',
    MAXIMUM_DOC_CATEGORY_LENGTH: 'Category name should not exceed 100 characters.',
    MINIMUM_DOC_CATEGORY_LENGTH: 'Category name must be at least 3 characters long.',
    NO_CUSTOMER_ASSIGNED: 'This user has no customer assigned',
    HIRING_NAME_REQUIRED: 'Hiring Client Name is required',
};

export const TOOLTIP_MESSAGES = {
    NO_GRADE_SELECTED: 'No Grade selected',
};
